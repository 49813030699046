import React, {useState, useEffect} from "react";
import { View, Text, Image, StyleSheet, TextInput, Linking, ScrollView } from "react-native";
import i18n from "i18n-js";
import CustomButton from "./CustomButton";
import * as DocumentPicker from 'expo-document-picker';
import {onAuthStateChanged, getAuth} from "firebase/auth";
import {logOut, myUser, sendVerification, setDisplayName, uploadUserFile, getUserFile, deleteUserFile, getUserData, getJobs} from "../Modules/Firebase"
import { useToast } from "react-native-toast-notifications";
import SkillView from "./SkillView";
import AppliedJobs from "./AppliedJobs";
import OwnerView from "./OwnerView";

export default function AccountView({setToast, navigation}){
    const [user, setUser] = useState(myUser());
    const [emailVerified, setEmailVerified] = useState(myUser()?.emailVerified);
    const [name, setName] = useState(getAuth()?.currentUser?.displayName);
    const [skill, setSkill] = useState("");
    const [skills, setSkills] = useState([]);
    const [userData, setUserData] = useState(null);
    const [jobsApplied, setJobsApplied] = useState(null);
    const [resume, setResume] = useState(null);
    const [toastMessage, setToastMessage] = useState("");
    const toast = useToast();
    if(skills == false){

    }
    if(userData == null){
        getUserData(setUserData, null);
    }else{
        console.log( userData);
    }
    if(userData && jobsApplied == null && userData.jobsApplied){
        getJobs(setJobsApplied, null, userData.jobsApplied);
    }
    
    
    
    useEffect(() => {
        if(toastMessage != ""){
            toast.show(toastMessage);
            setToastMessage("");
        }
        
    }, [toastMessage, setToastMessage]);
    const signOutCallback = function (event){
        if(event.isError == false && setToast){
            setToast(i18n.t("accountViewAlertSignedOut"));
        }else if(event.isError && setToast){
            setToast(event.error);
        }
    }
    const verificationCallback = function (event){
        if(event.isError == false && setToast){
            setToast(i18n.t("accountViewAlertVerficationSend"));
        }else if(event.isError && setToast){
            setToast(event.error);
        }
    }
    const setNameCallback = function (event){
        if(event.isError == false && setToast){
            setToast(i18n.t("accountViewAlertNameSet"));
        }else if(event.isError && setToast){
            setToast(event.error);
        }
    }
    const resumeCallback = function (event){
        if(event.isError == false && event.url){
            setResume(event.url);
        }
        if(event.isError == false && setToast){
            setToast(i18n.t("accountViewAlertResumeUpload"));
        }else if(event.isError && setToast){
            setToast(event.error);
            setResume(false);
        }
    }
    const resumeCallbackNoToast = function (event){
        if(event.isError == false && event.url){
            setResume(event.url);
        }
    }
    const resumeCallbackDelete = function (event){
        if(event.isError == false && setToast){
            setToast(i18n.t("accountViewAlertResumeDelete"));
            setResume(false);
        }else if(event.isError && setToast){
            setToast(event.error);
        }
    }
    const getResume = async function () {
        const results = await DocumentPicker.getDocumentAsync({copyToCacheDirectory:true, type:["application/pdf"]}); //, "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword"
        
        if(results?.type == "success" && results?.uri){
            const r = await fetch(results.uri);
            const b = await r.blob();
            uploadUserFile(b, resumeCallback, "resume.pdf");
        }
        
    } 
    const viewResume = function () {
        if(resume){
            Linking.openURL(resume);
        }
    } 
    const deleteResume = function () {
         if(resume){
            deleteUserFile("resume.pdf", resumeCallbackDelete);
            setResume(false);
        }
    } 
    if(resume == null){
        getUserFile("resume.pdf", resumeCallbackNoToast);
        setResume(false);
    }
    return(
        <View >
            {(()=>{
                if(emailVerified && userData && userData.owner){
                    return(<OwnerView setToast={setToast} ownerId={userData.owner} navigation={navigation} onSignOut={()=>logOut(signOutCallback)} />)
                }else if(emailVerified && userData){
                    return(
                        <ScrollView contentContainerStyle={{flex:1}} style={styles.container}>
                            <Text style={styles.title}>{i18n.t("accountViewTitleInfo")}:</Text>
                            <Text style={styles.secondaryTitle}>{i18n.t("accountViewDisplayNameTitle")}:</Text>
                            <TextInput 
                            style={styles.displayNameTextInput} 
                            placeholder={i18n.t("accountViewDisplayPlaceName")} 
                            value={name? name: ""}
                            returnKeyType={"done"}
                            onSubmitEditing={() => {
                                if(name && name != ""){
                                    setDisplayName(name, setNameCallback)
                                }
                            }}
                            onBlur={() => {
                                if(name && name != ""){
                                    setDisplayName(name, setNameCallback)
                                }
                            }}
                            onChangeText={text => setName(text)} />

                            
                            <Text style={styles.secondaryTitle}>{i18n.t("accountViewResumeTitle")}:</Text>
                            {resume?
                                <>
                                    <CustomButton text={i18n.t("accountViewResumeView")} onPress={() =>{
                                        viewResume();
                                    }}/>
                                    <CustomButton text={i18n.t("accountViewResumeDelete")} onPress={() =>{
                                        deleteResume();
                                    }}/>
                                </>
                                :
                                <>
                                <Text style={styles.labelText}>{i18n.t("accountViewResumeDelete")}</Text>
                                <CustomButton text={i18n.t("accountViewAddResume")} onPress={() =>{
                                    getResume();
                                }}/>
                                </>
                            }
                            {/* 
                            <Text style={styles.secondaryTitle}>{i18n.t("accountViewSkillTitle")}:</Text>
                            <TextInput 
                            style={styles.displayNameTextInput} 
                            placeholder={"type skill here"} 
                            value={skill? skill: ""}
                            returnKeyType={"done"}
                            onSubmitEditing={()=>{
                                
                                setSkills([...skills,skill]);
                                setSkill("");
                                console.log(skills);
                            }}
                            onChangeText={text => setSkill(text)} />
                            {skills && skills.length > 0? 
                                <Text style={styles.secondaryTitle}>{i18n.t("accountViewSkillViewTitle")}:</Text>
                            :
                                undefined
                            }
                            <SkillView skills={skills} onSkillEdit={() =>{
                                
                            }} />
                            */}
                            <Text style={styles.secondaryTitle}>{i18n.t("accountViewJobsAppliedTitle")}:</Text>
                            <AppliedJobs jobs={jobsApplied} navigation={navigation} />
                            
                            <View style={styles.signOutButtonView}>
                                <CustomButton  text={i18n.t("accountViewSignOutButton")} onPress={() =>{
                                    logOut(signOutCallback);
                                }}/>
                                <View style={{marginTop:40}} />
                                <CustomButton  text={i18n.t("accountViewDeleteButton")} onPress={() =>{
                                    Linking.openURL("https://forms.gle/Bkh3ytLFyWf5ifDn8");
                                }}/>
                                
                            </View>
                        </ ScrollView>
                    )
                }else if(emailVerified == false && userData){
                    return(
                        <>
                            <Text>{i18n.t("accountViewVerifyEmail")}</Text>
                            <CustomButton  text={i18n.t("accountViewSendVerfEmail")} onPress={() =>{
                                sendVerification(verificationCallback);
                            }}/>
                            <CustomButton  text={i18n.t("accountViewRefresh")} onPress={async() =>{
                                
                                await getAuth().currentUser?.reload();
                                setEmailVerified(getAuth().currentUser?.emailVerified);
                            }}/>

                            <View style={styles.signOutButtonView}>
                                <CustomButton  text={i18n.t("accountViewSignOutButton")} onPress={() =>{
                                    logOut(signOutCallback);
                                }}/>
                                
                            </View>
                            
                        </>
                    )
                }else{
                    return(
                        <>
                        <Text>{i18n.t("loading")}</Text>
                        </>
                    )
                }
            })()}
            
            
        </View>
    )
}

const styles = StyleSheet.create({
    container:{
        flex:1,
        alignSelf:"center",
    },
    title:{
        marginBottom:20, 
        fontSize: 20,
        fontWeight:"bold",
        textAlign:"center",
    },
    secondaryTitle:{
        marginBottom:5,
        marginTop: 15,
        fontSize: 15,
        fontWeight:"bold",
        textAlign:"left",
    },
    labelText:{
        fontSize:12,
        marginBottom:5, 
        textAlign:"center",
    },
    displayNameTextInput:{
        marginBottom: 20,
    },
    signOutButtonView:{
        flex:1,
        justifyContent:'flex-end',
        marginTop: 20,
        marginBottom: 30,

    }
})