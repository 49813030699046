import React, {useState, useEffect} from "react";
import { useToast } from "react-native-toast-notifications";

export default function ShowToast({message, didShow}) {
    const toast = useToast();
    const [toastMessage, setToastMessage] = useState(message); 
    React.useEffect(() => {
        if(toastMessage !=""){
            toast.show(toastMessage);
            if(didShow){
                didShow()
            }
            setToastMessage("");
        }

    }, [toastMessage, setToastMessage]);
    if(message!= toastMessage){
        setToastMessage(message)
    }
    return(<></>);
}