import React, {useState} from "react";
import {View, Text, StyleSheet, TouchableOpacity} from "react-native"
import isMobile from "../Modules/isMobile";
import { Ionicons } from '@expo/vector-icons';
import i18n from 'i18n-js';
export default function SaveForLater({id, userData, onPress}) {
    
    const [isSaved, setSaved] =useState(false);
    function checkForSavedJob(){
        let contains = false;
        userData.saveForLater.forEach(element => {
            if(element===id){
                contains = true;
            }
        });
        return contains;
    }
    if(isSaved == false && userData && userData.saveForLater && checkForSavedJob() == true){
        setSaved(true);
    }else if(isSaved == true && userData && userData.saveForLater && checkForSavedJob() == false){
        setSaved(false);
    }
    return(<TouchableOpacity onPress={onPress} style={styles.container}>
            <Ionicons name={isSaved?"heart":"heart-outline"} size={18}></Ionicons>
    </TouchableOpacity>)
    
}

const styles = StyleSheet.create({
    container:{
        padding:5,
        borderRadius:10,
        justifyContent:"center",
        marginBottom: 10,
        marginHorizontal:20,
        backgroundColor: "lightgray",
    },
    title:{
        fontSize: 19,
        fontWeight: "bold",
        marginBottom: 5,
    },
    location:{
        marginBottom: 5,
    }
    
})