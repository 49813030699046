import React, {useState, useRef, createRef} from "react";
import {TextInput, View, Text, StyleSheet, TouchableOpacity, Modal, Pressable, ScrollView, SafeAreaView, TouchableWithoutFeedback, Linking} from "react-native"
import isMobile from "../Modules/isMobile";
import { Ionicons } from '@expo/vector-icons';
import i18n from 'i18n-js';
import CustomButton from "./CustomButton";
import { getUserFile, applyToJob, uploadJobFile } from "../Modules/Firebase";
import * as DocumentPicker from 'expo-document-picker';
import {onAuthStateChanged, getAuth} from "firebase/auth";
import {viewFile} from "../Modules/GlobalFunctions"
export default function ApplyView({data, visible, hideModal, navigation, id, setToast}) {
    const [isSignedIn, setSignedIn]  = useState(false);
    const [accountResume, setAccountResume]  = useState(null);
    const [uploadResume, setUploadResume]  = useState(null);
    const [coverLetter, setCoverLetter]  = useState(null);
    var questions = React.useRef([]);
    var cachedData = null;

    if(data && data.applyData ){
      for (var i = 0; i < questions.current.length; i++) {
        if(questions.current[i] && questions.current[i].current == null){
          questions.current.splice(i, 1);
        }
      }
      data?.applyData.map((data, index)=>{
        if(data.type === "question"){
          questions.current.push(createRef());
        }
      });
      
    }
    function handleAccountResume(event){ 
      if(event.isError == false && event.url){
        setAccountResume(event.url);
      }
    }

    if(isSignedIn == true && accountResume == null){
      getUserFile("resume.pdf", handleAccountResume);
      setAccountResume(false);
    }

    function applyCallback(event){
      if(event.jobSubmit == true){
        setToast("Applied to Job");
        hideModal();
      }
    }
    function resumeAndCoverCallback(event){
      var fileType = null;
      if(event.name){
        if(event.name.includes("Resume")){
          fileType = "resume";
        }else if(event.name.includes("CoverLetter")){
          fileType = "coverLetter";
        }
      }
      
      if(event.isError == false && event.url){
        if(fileType){
          cachedData[fileType] = event.url;
        }
        if(cachedData != null && (cachedData.resume == null || cachedData.resume !== "waiting") && (cachedData.coverLetter == null || cachedData.coverLetter !== "waiting")){
          applyToJob(id, cachedData, applyCallback);
        }
      }else{
        if(fileType === "resume"){
          setToast("Unable to submit Resume to apply");
        }else if(fileType === "coverLetter"){
          setToast("Unable to submit Cover Letter to apply");
        }
        
      }
    }

    function submitApplication(){          
      if(getAuth().currentUser){
        for (var i = 0; i < questions.current.length; i++) {
          if(questions.current[i] && questions.current[i].current == null){
            questions.current.splice(i, 1);
          }
        }
        var applyData = {};
        var questionIndex = 0;
        var missingParm = null;
        var tempResume = null;
        var tempCover = null;
        applyData.userId = getAuth().currentUser?.uid;
        applyData.displayName = getAuth().currentUser?.displayName;
        applyData.email = getAuth().currentUser?.email;
        data?.applyData.map((data, index)=>{
          if(data.type === "resume"){
            if(data.required == true && uploadResume == null){
              missingParm = data.type
            }
            if(uploadResume == null){
              return;
            }
            
            if(typeof uploadResume === "string"){
              applyData.resume = uploadResume;
            }else{
              tempResume = uploadResume;
            }
          }else if(data.type === "coverLetter"){
            if(data.required == true && coverLetter == null){
              missingParm = data.type
            }
            if(coverLetter == null){
              return;
            }
            
            tempCover = coverLetter;
          }else if(data.type === "question"){
            if(applyData.questions == null){
              applyData.questions = [];
            }
            console.log(questions.current)
            if(questions.current[questionIndex] == null || questions.current[questionIndex].current == null ||  questions.current[questionIndex].current.value == null ||  questions.current[questionIndex].current.value === ""){
              missingParm = data.type;
            }else{
              applyData.questions.push(questions?.current[questionIndex].current.value);
            }
            questionIndex += 1;
          }
          
        })
        if(missingParm == null){
          if(tempResume || tempCover ){
            
            if(tempResume){
              applyData.resume ="waiting";
              uploadJobFile(tempResume, id, resumeAndCoverCallback, getAuth().currentUser?.uid+"_Resume.pdf");
            }
            if(tempCover){
              applyData.coverLetter ="waiting";
              uploadJobFile(tempCover, id, resumeAndCoverCallback, getAuth().currentUser?.uid+"_CoverLetter.pdf");
            }
            cachedData = applyData;
            
          }else{
            applyToJob(id, applyData, applyCallback);
          }
        }else{
          var toastMessage = "Missing Infomation";
          if(missingParm == "resume"){
            toastMessage = "Resume Required";
          }else if(missingParm == "coverLetter"){
            toastMessage = "Cover Letter Required";
          }else if(missingParm == "question"){
            toastMessage = "Question Left Blank";
          }
          setToast(toastMessage);
        }
      }
     
    }
    onAuthStateChanged(getAuth(), (user) => {
        if (user) {
          setSignedIn(true);
        } else {
          setSignedIn(false);
        }
    });
    const getResume = async function () {
      const results = await DocumentPicker.getDocumentAsync({copyToCacheDirectory:true, type:["application/pdf"]}); //, "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword"
      
      if(results?.type == "success" && results?.uri){
          const r = await fetch(results.uri);
          const b = await r.blob();
          setUploadResume(b);
      }
    } 
    const resumeSelector = function(required){
      return(
      <View>
        <Text>Upload Resume {required? "(required)": "(optional)"}</Text>
        {(() =>{
          if(uploadResume){
            return(
              <>
                <CustomButton text={"View Resume"} onPress={()=>{
                    viewFile(uploadResume);
                  }} />
                <CustomButton text={"Remove Resume"} onPress={()=>setUploadResume(null)} />
              </>
            )
          }else if(uploadResume == null){
            return(
              <CustomButton text={"Upload Custom Resume"} onPress={()=>{
                getResume();
              }} />
            )
          }else{
            return(
              <>
              </>
            )
          }
        })()}
        {accountResume && uploadResume==null?
            <>
              <CustomButton text={"Use Account Resume"} onPress={()=>setUploadResume(accountResume)} />
            </>
            :
            undefined
          }
        
        
      </View>
    )}
    const getCover = async function () {
      const results = await DocumentPicker.getDocumentAsync({copyToCacheDirectory:true, type:["application/pdf"]}); //, "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword"
      
      if(results?.type == "success" && results?.uri){
          const r = await fetch(results.uri);
          const b = await r.blob();
          setCoverLetter(b);
      }
    } 
    const coverSelector = function(required){
      return(
      <View>
        <Text>Upload Cover Letter {required? "(required)": "(optional)"}</Text>
        {(() =>{
          if(coverLetter){
            return(
              <>
                <CustomButton text={"View Cover Letter"} onPress={()=>viewFile(coverLetter)} />
                <CustomButton text={"Remove Cover Letter"} onPress={()=>setCoverLetter(null)} />
              </>
            )
          }else if(coverLetter == null){
            return(
              <CustomButton text={"Upload Cover Letter"} onPress={()=>getCover()} />
            )
          }else{
            return(
              <>
              </>
            )
          }
        })()}
        
        
      </View>
    )}
    function getTopQuestionIndex(){
      for (let i = 0; i < questions.current.length; i++) { 
        if(questions.current[i] && questions.current[i].current == null){
          return i;
        }
      }
    }
    const questionSelector = (data, index)=>{
      
      var indexOfCurrent = getTopQuestionIndex();
      return (
        <View>
          {(()=>{
            if(data?.questionType==="longText"){
              return (<View>
                  <Text>{data.question} {data.required? "(required)": "(optional)"}</Text>
                  <TextInput ref={questions.current[indexOfCurrent]} multiline style={styles.questionTextBox}/>
                </View>)
            }else{
              return (<>
                </>)
            }

          })()}
        </View>
      )
    }
    return(
        <Modal animationType="slide"
            transparent={true}
            visible={visible}>
            <TouchableOpacity style={styles.modalBackground} onPress={hideModal}>
                
                {isSignedIn? <TouchableWithoutFeedback>
                  <View style={styles.modalView}>
                    <ScrollView contentContainerStyle={{justifyContent:"center", alignContent:"center", flex:1}}>
                    <View style={styles.applyView}>
                        {data?.applyData?.map((data, index)=>{
                          
                          if(data.type === "resume"){
                            return resumeSelector(data.required);
                          }else if(data.type === "coverLetter"){
                            return coverSelector(data.required);
                          }else if(data.type === "question"){
                            return questionSelector(data, index);
                          }
                          
                        })}
                      <View style={{marginTop:50}} />
                      <CustomButton text={"Apply"} onPress={()=>submitApplication()} />
                      </View>
                    </ScrollView>
                  </View>
                </TouchableWithoutFeedback>:
                <View style={styles.modalView}>
                  <Text style={{marginBottom: 10}}>You must be signed in to apply</Text>
                  <CustomButton text={"Sign In"} onPress={()=>{
                    hideModal();
                    navigation.navigate("Account")
                  }} />
                </View>}
              
            </TouchableOpacity>
        </Modal>
    )
    
}

const styles = StyleSheet.create({
    modalBackground:{
      flex:1,
      backgroundColor:"rgba(0, 0, 0, 0.42)",
      height: "100%",
      width: "100%",
      alignItems: 'center',
        justifyContent: 'center',
        
    },  
    modalView:{
        opacity: 1,
        borderRadius: 20,
        alignSelf:"center",
        justifyContent: 'center',
        padding: 10,
        height: "70%",
        width: "90%",
        textAlign:"center",
        backgroundColor:"lightgray"
    },
    questionTextBox:{
      marginVertical: 8,
      marginHorizontal: 20,
      backgroundColor:"white",
      height:50,
    },
    applyView:{
      flex:0.7
    }
    
    
});