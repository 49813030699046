import React, {useState} from "react";
import {View, Text, StyleSheet, TouchableOpacity} from "react-native"
import isMobile from "../Modules/isMobile";
import { Ionicons } from '@expo/vector-icons';
import i18n from 'i18n-js';
export default function JobListItem({data, onPress, jobId, isMobile}) {
    const wordCountDescription = 30;
    var isSelected = isMobile? false:data.id == jobId;
    
    return(<TouchableOpacity disabled={data.title === "Loading..." || isSelected} style={isSelected? [styles.container, {borderColor:"#800080"}] :styles.container} onPress={()=> onPress(data)}>
            <Text style={styles.title}>{data.title}</Text>
            {data.location? <Text style={styles.location}>{data.location}</Text> : undefined }
            {data.longDescription? <Text>{data.longDescription.split(/\s+/).slice(0,wordCountDescription).join(" ") + "..."}</Text> : undefined }
    </TouchableOpacity>)
    
}

const styles = StyleSheet.create({
    container:{
        flexDirection: "column",
        alignItems:"center",
        justifyContent:"center",
        textAlign:"center",
        padding: 15,
        minHeight: 150,
        borderWidth:2,
        borderColor:"black",
        borderRadius:25,
        marginBottom:10,
        flex:1,
        backgroundColor:"white",
    },
    title:{
        fontSize: 19,
        fontWeight: "bold",
        marginBottom: 5,
    },
    location:{
        marginBottom: 5,
    }
    
})