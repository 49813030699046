import React, {useState} from "react";
import { View, StyleSheet , Text, Pressable, Linking} from "react-native";
import { Ionicons } from '@expo/vector-icons';
import isMobile from "../Modules/isMobile";
import i18n from "i18n-js";
export default function Footer({navigation}) {
   
    return(
        <View style={styles.footerView}>
            <View style={[styles.linkView, isMobile()?undefined:{flexDirection:"row", marginLeft:0}]}>
                <Pressable onPress={()=>{
                    navigation.navigate("Jobs")
                }}>
                    <Text style={[styles.linkText, isMobile()?undefined:{marginHorizontal:10}]}>{i18n.t("footerViewJobs")}</Text>
                </Pressable>
                <Pressable onPress={()=>{
                    navigation.navigate("Companies")
                }}>
                    <Text style={[styles.linkText, isMobile()?undefined:{marginHorizontal:10}]}>{i18n.t("footerViewCompanies")}</Text>
                </Pressable>
                <Pressable onPress={()=>{
                    navigation.navigate("Certificates")
                }}>
                    <Text style={[styles.linkText, isMobile()?undefined:{marginHorizontal:10}]}>{i18n.t("footerGetCertificated")}</Text>
                </Pressable>
                <Pressable onPress={()=>{
                    navigation.navigate("Hire")
                }}>
                    <Text style={[styles.linkText, isMobile()?undefined:{marginHorizontal:10}]}>{i18n.t("footerPostJob")}</Text>
                </Pressable>
                <Pressable onPress={()=>{
                    navigation.navigate("About")
                }}>
                    <Text style={[styles.linkText, isMobile()?undefined:{marginHorizontal:10}]}>{i18n.t("footerAbout")}</Text>
                </Pressable>
                <Pressable onPress={()=>{
                    Linking.openURL("mailto:lefaster97@gmail.com?subject=Interested%20in%20working%20at%20Baara&body=Why%20do%20you%20want%20to%20work%20at%20Baara%3F%0D%0A%0D%0A%0D%0AWhat%20project%20have%20you%20worked%20on%20in%20the%20past%3F%0D%0A%0D%0A%0D%0APlease%20attach%20your%20resume%20and%20a%20cover%20letter")
                }}>
                    <Text style={[styles.linkText, isMobile()?undefined:{marginHorizontal:10}]}>{i18n.t("footerWorkAtBaara")}</Text>
                </Pressable>
            </View>
            <View style={styles.socialMediaView}>
                <Pressable onPress={()=>{
                    Linking.openURL("https://www.facebook.com/baarajob/")
                }}>
                    <Ionicons name="logo-facebook" size={40} color={"#800080"} style={styles.socialMediaIcon}/>
                </Pressable>
                <Pressable onPress={()=>{
                    Linking.openURL("https://twitter.com/baarajob")
                }}>
                    <Ionicons name="logo-twitter" size={40} color={"#800080"} style={styles.socialMediaIcon}/>
                </Pressable>
                <Pressable onPress={()=>{
                    Linking.openURL("https://www.youtube.com/channel/UCNrtocRtDMThdrQ3tMO4RRg")
                }}>
                    <Ionicons name="logo-youtube" size={40} color={"#800080"} style={styles.socialMediaIcon}/>
                </Pressable>
                <Pressable onPress={()=>{
                    Linking.openURL("https://www.instagram.com/baarajob/")
                }}>
                    <Ionicons name="logo-instagram" size={40} color={"#800080"} style={[styles.socialMediaIcon, {marginHorizontal: 4}]}/>
                </Pressable>
            </View>
            <Text style={{color:"black", textAlign:"right", paddingRight:20, marginLeft:30}}>{'\u00A9'}Edine Fadel Abdoulaye Landoure</Text>
            
        </View>

    )
   
}

const styles = StyleSheet.create({
    
    footerView:{

        paddingVertical: 10,
        backgroundColor: "white",
        justifyContent:"flex-end",
        marginTop:"auto",
        width:"100vw",
        borderTopWidth:1,
        borderTopColor:"lightgray"
    },
    linkView:{
        color:"black",
        marginLeft:8,
    },
    linkText:{
        paddingVertical:3,
        color:"black",
        textDecorationLine:"underline"
    },
    socialMediaView:{
        flexDirection:"row",
        alignItems:"center",
        marginTop:5,
        justifyContent:"flex-end",
    },
    socialMediaIcon:{
        marginHorizontal:8,
    }
    
});