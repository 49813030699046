import React, {useState} from "react";
import { View, Text, StyleSheet , TextInput} from "react-native";
import i18n from "i18n-js";
export default function SearchBar({placeholderType, onSearch}) {
    const [searchText, setSearchText] = useState("");
    let placeholder = "Search";
    if(placeholderType === "Jobs"){
        placeholder = i18n.t("searchJobsPlaceholder");
    }else if(placeholderType === "Companies"){
        placeholder = i18n.t("searchCompaniesPlaceholder");
    }

    return (
       <View style={styles.searchView}>
            <View style={styles.inputView}>
                <TextInput 
                onChangeText={(value) => setSearchText(value)}
                style={styles.searchInput} 
                value={searchText} 
                returnKeyType={"search"}
                onSubmitEditing={()=>onSearch(searchText, placeholderType)} placeholder={placeholder}/>
            </View>           
       </View>
    )
}

const styles = StyleSheet.create({
    searchInput:{
        minHeight: 30,
        paddingHorizontal: 20,
        backgroundColor:"lightgray",
        borderRadius: 10,
    },
    inputView:{
        backgroundColor:"white",
        marginHorizontal: 20,
        marginVertical: 10,
    },
    searchView:{
        flex:1,
        minHeight: 50,
        backgroundColor:"white",
        borderBottomColor: "lightgray",
        borderBottomWidth: 2,
    }
});