import * as Localization from 'expo-localization';
import i18n from "i18n-js";


i18n.translations = {
    en: { 
        headerTitle: "BAARA",
        navTitleJobs: "Jobs",
        navTitleCompanies: "Companies",
        navTitleAccount: "Account",
        navTitleSignUp: "Sign Up / Sign In",
        navTitleAbout: "About",
        navTitleCertificates:"Certificates",
        navTitleHire:"Hire",
        

        homePageDescription: "Helping people get jobs in Burkina Faso",

        accountSignUpTitle: "Sign Up",
        accountSignUpEmailLabel: "Email:",
        accountSignUpPasswordLabel: "Password:",
        accountSignUpPasswordVerifyLabel: "Retype-Password:",
        accountSignUpButton: "Sign Up:",
        accountSignInTitle: "Sign In",
        accountSignInEmailLabel: "Email:",
        accountSignInPasswordLabel: "Password:",
        accountSignInButton: "Sign In:",
        accountEmailBlankAlert: "Email blank",
        accountPasswordBlankAlert: "Password blank",
        accountPasswordsMatchAlert: "Passwords do not match",
        accountAlertSignedUp: "Signed Up",
        accountAlertSignedIn: "Signed In",
        accountForgotPasswordTitle:"Forgot Password",
        accountSignUpButtonText:"Sign Up",
        accountSignInButtonText:"Sign In",
        accountForgotButtonText:"Forgot your password?",
        accountResetButtonText:"Reset Password",
        accountResetCancelButtonText:"Cancel",
        


        accountViewTitle: "You are Signed In",
        accountViewWelcome: "You are Signed In",
        accountViewVerifyEmail: "Please verfiy your email to view your account",
        accountViewSignOutButton: "Sign Out",
        accountViewTitleInfo:"Account Info",
        accountViewDeleteButton:"Request Account Deletion",
        accountViewDisplayNameTitle:"Set Display Name",
        accountViewSkillTitle:"Add Skills",
        accountViewSkillViewTitle: "Skills",
        accountViewResumeTitle:"Account Resume",
        accountViewJobsAppliedTitle:"Applied Jobs",
        accountViewAlertSignedOut:"Signed Out",
        accountViewAlertVerficationSend:"Send Verfication Email",
        accountViewAlertNameSet:"Display Name Set",
        accountViewAlertResumeUpload:"Resume Uploaded",
        accountViewAlertResumeDelete:"Resume Deleted",
        accountViewResumeView:"View Resume",
        accountViewResumeDelete:"Delete Resume",
        accountViewResumeTypeText:"Only PDF file type are supported",
        accountViewAddResume:"Add Resume",
        accountViewSendVerfEmail:"Send Verfication Email",
        accountViewRefresh:"Refresh",
        accountViewDisplayPlaceName:"Name",
        
        

        searchJobsPlaceholder:"Search Jobs",
        searchCompaniesPlaceholder:"Search Companies",

        jobTypeFull: "Full Time",
        jobTypePart: "Part Time",


        companyPageAlreadyReviewedAlert: "Account already reviewed page",
        companyPageAlreadyOwnerAlert: "Owners cannot review thier own page",
        
        reviewsYouText:"(You)",

        reviewsDeleteReview: "You are about to delete your review",

        addReviewSubmitedReview: "Review Added",
        addReviewUpdatedReview: "Review Updated",
        addReviewAddReviewTitle:"Add Review",
        addReviewUpdateReviewTitle:"Update Review",
        addReviewSubmitReviewButton:"Submit Review",
        addReviewUpdateReviewButton:"Update Review",
        addReviewAlertBlankReview:"Review cannot be blank",
        addReviewAlertStarReview:"Stars cannot be 0",
        addReviewMustBeSignedInWarn:"You must be signed in to add a review",
        addReviewSignInButton:"Sign In",
        
        globalFunctionsVerifyAction: "Verfiy Action",
        globalFunctionsVerifyActionOk: "Ok",
        globalFunctionsVerifyActionCancel: "Cancel",

        ownerViewTitleInfo: "Owner Info",
        ownerViewDisplayNameTitle: "Set Display Name",
        ownerViewJobsPosted:"Jobs Posted",
        ownerViewCompanyNameTitle:"Company Name",
        ownerViewCompanyPageButton:"View Company Page",
        ownerViewAddPhotoButton:"Upload Logo",
        ownerViewAddNewJob:"Add New Job",
        ownerViewCompanyAboutTitle:"About Company",
        ownerViewDeleteJobWarning:"You are about to delete a job",

        addJobEditJob:"Edit Job",
        addJobAddJob:"Add Job",
        addJobUpdateButton:"Update Job",
        addJobGetApplicationsButton:"Export/Get Applications",
        addJobEditTitle:"Job Title",
        addJobEditLocation:"Location",
        addJobEditLongDescription: "Long Description",
        addJobEditSalary: "Salary",
        addJobEditJobActive: "Job Active",
        addJobEditSearchTags:"Search Tags",
        addJobEditBenefits:"Job Benefits",
        addJobUpdatedJob:"Job Updated",
        addJobAddedJob:"Job Added",
        addJobAddButton:"Add Job",
        addJobNoAppData:"No Applications submited for job",
        addJobEnglishTitle:"English",
        addJobFrenchTitle:"French",
        addJobEditJobType:"Job Type",
        addJobEditJobTypeFull:"Full Time Job",
        addJobEditJobTypePart:"Part Time Job",
        
        aboutTitle:"About BAARA",
        aboutMissionTitle:"Mission",
        aboutMission:"To become a one-stop solution for hiring, job searching, online course offering, and skill development. Bringing everyone involved in this field to one location, to decrease costs, and increase quality, efficiency, ease, and transparency. So, that everyone can learn and earn irrespective of where they are both physically and in their career paths.",
        aboutVisionTitle:"Vision",
        aboutVision:"To provide skills to everyone looking to excel in their field. to become a symbol of trust, transparency, and hope for everyone. To help everyone achieve their dreams by providing them with the right education and resources.",
        aboutContactTitle:"Contact",
        aboutContactButton:"Email Us",
        aboutSocialButton:"Connect with us",

        footerViewJobs:"View Jobs",
        footerViewCompanies:"View Companies",
        footerGetCertificated:"Get Certificated",
        footerAbout:"About",
        footerPostJob:"Post Jobs",
        footerWorkAtBaara:"Work at Baara",

        launchTitle:"Find Jobs with BAARA",
        launchWhatToSearch:"What are you looking for?",
        launchWhereToSearch:"Where are you looking for a job?",
        launchSearchButton:"Find Jobs",
        launchWhatTextbox:"Job Title, Company Name, or Tag",
        launchWhereTextbox:"City, Zip code, or \"online\"",
        launchPostJob:"Are you an Employer?",
        launchPostJobButton:"Post a Job",

        hireTitle:"Post jobs to find the perfect employees",
        hireSubtitle:"Contact us about adding your company and jobs to our site",
        hireButton:"Submit Form",

        certificatesTitle:"Get Certified",

        certificatesViewLearnMore:"Learn More",

        jobFilterRecent:"Recent",
        jobFilterLoved:"Saved",
        jobFilterRecommend: "Recommend",
        jobsFilterNoSavedJobs:"No jobs saved",
        jobsFilterSignIn:"You need to sign in first",
        jobsFilterNoRecommend:"No jobs to recommend yet",

        loading:"Loading...",

        companyPageJobsText:"Jobs:",
        companyPageReviewsText:"Reviews:",
        companyPageAddReviewText:"Add Review",
        
    },

    fr:{
        headerTitle: "BAARA",
        navTitleJobs: "Emplois",
        navTitleCompanies: "Entreprises",
        navTitleAccount: "Compte",
        navTitleSignUp: "S’inscrire / Se connecter",
        navTitleAbout: "Environ",
        navTitleCertificates: "Certificats",
        navTitleHire:"Embaucher",

        homePageDescription: "Aider les gens à trouver un emploi au Burkina Faso",

        accountSignUpTitle: "S’enregistrer",
        accountSignUpEmailLabel: "Messagerie électronique:",
        accountSignUpPasswordLabel: "Mot de passe:",
        accountSignUpPasswordVerifyLabel: "Retapez le mot de passe :",
        accountSignUpButton: "S’enregistrer:",
        accountSignInTitle: "Connexion",
        accountSignInEmailLabel: "Messagerie électronique:",
        accountSignInPasswordLabel: "Mot de passe:",
        accountSignInButton: "Connexion:",
        accountEmailBlankAlert: "E-mail vide",
        accountPasswordBlankAlert: "Mot de passe vide",
        accountPasswordsMatchAlert: "Les mots de passe ne correspondent pas",
        accountAlertSignedUp: "Inscrit",
        accountAlertSignedIn: "Connecté",
        accountForgotPasswordTitle: "Mot de passe oublié",
        accountSignUpButtonText: "S’enregistrer",
        accountSignInButtonText: "Connexion",
        accountForgotButtonText: "Vous avez oublié votre mot de passe?",
        accountResetButtonText: "Réinitialiser le mot de passe",
        accountResetCancelButtonText: "Annuler",

        accountViewTitle: "Vous êtes connecté",
        accountViewWelcome: "Vous êtes connecté",
        accountViewVerifyEmail: "Veuillez vérifier votre adresse e-mail pour afficher votre compte",
        accountViewSignOutButton: "Se déconnecter",
        accountViewTitleInfo: "Informations sur le compte",
        accountViewDeleteButton:"Demander la suppression d'un compte",
        accountViewDisplayNameTitle: "Définir le nom d’affichage",
        accountViewSkillTitle: "Ajouter des compétences",
        accountViewSkillViewTitle: "Compétences",
        accountViewResumeTitle: "Reprise du compte",
        accountViewJobsAppliedTitle: "Emplois appliqués",
        accountViewAlertSignedOut: "Déconnecté",
        accountViewAlertVerficationSend: "Envoyer un e-mail de vérification",
        accountViewAlertNameSet: "Jeu de noms complets",
        accountViewAlertResumeUpload: "CV téléchargé",
        accountViewAlertResumeDelete: "Reprendre supprimé",
        accountViewResumeView: "Voir le CV",
        accountViewResumeDelete: "Supprimer le CV",
        accountViewResumeTypeText: "Only PDF file type are supported",
        accountViewAddResume: "Add Resume",
        accountViewSendVerfEmail: "Envoyer un e-mail de vérification",
        accountViewRefresh: "Refresh",
        accountViewDisplayPlaceName: "Name",

        searchJobsPlaceholder: "Rechercher des emplois",
        searchCompaniesPlaceholder: "Rechercher des entreprises",

        jobTypeFull: "Temps plein",
        jobTypePart: "Temps partiel",

        companyPageAlreadyReviewedAlert: "Page Compte déjà examiné",
        companyPageAlreadyOwnerAlert: "Les propriétaires ne peuvent pas consulter leur propre page",

        reviewsYouText: "(Vous)",
        reviewsDeleteReview: "Vous êtes sur le point de supprimer votre avis",

        addReviewSubmitedReview: "Avis ajouté",
        addReviewUpdatedReview: "Révision mise à jour",
        addReviewAddReviewTitle: "Ajouter un avis",
        addReviewUpdateReviewTitle: "Examen des mises à jour",
        addReviewSubmitReviewButton: "Soumettre un avis",
        addReviewUpdateReviewButton: "Examen des mises à jour",
        addReviewAlertBlankReview: "La révision ne peut pas être vide",
        addReviewAlertStarReview: "Les étoiles ne peuvent pas être égales à 0",
        addReviewMustBeSignedInWarn: "Vous devez être connecté pour ajouter un avis",
        addReviewSignInButton: "Connexion",

        globalFunctionsVerifyAction: "Verfiy Action",
        globalFunctionsVerifyActionOk: "D’accord",
        globalFunctionsVerifyActionCancel: "Annuler",

        ownerViewTitleInfo: "Informations sur le propriétaire",
        ownerViewDisplayNameTitle: "Définir le nom d’affichage",
        ownerViewJobsPosted: "Offres d’emploi publiées",
        ownerViewCompanyNameTitle: "Nom de l’entreprise",
        ownerViewCompanyPageButton: "Voir la page de l’entreprise",
        ownerViewAddPhotoButton: "Télécharger le logo",
        ownerViewAddNewJob: "Ajouter une nouvelle tâche",
        ownerViewCompanyAboutTitle: "À propos de l’entreprise",
        ownerViewDeleteJobWarning: "Vous êtes sur le point de supprimer une tâche",

        addJobEditJob: "Modifier la tâche",
        addJobAddJob: "Ajouter une tâche",
        addJobUpdateButton: "Mise à jour du travail",
        addJobGetApplicationsButton: "Exporter/Obtenir des applications",
        addJobEditTitle: "Titre du poste",
        addJobEditLocation: "Emplacement",
        addJobEditLongDescription: "Description longue",
        addJobEditSalary: "Salaire",
        addJobEditJobActive: "Emploi actif",
        addJobEditSearchTags: "Balises de recherche",
        addJobEditBenefits: "Avantages sociaux",
        addJobUpdatedJob: "Mise à jour de l’emploi",
        addJobAddedJob: "Emploi ajouté",
        addJobAddButton: "Ajouter une tâche",
        addJobNoAppData: "Aucune candidature soumise pour un emploi",
        addJobEnglishTitle: "Anglais",
        addJobFrenchTitle: "Français",
        addJobEditJobType: "Job Type",
        addJobEditJobTypeFull: "Full Time Job",
        addJobEditJobTypePart: "Part Time Job",
        
        aboutTitle: "À propos de BAARA",
        aboutMissionTitle: "Mission",
        aboutMission: "Devenir une solution unique pour l’embauche, la recherche d’emploi, l’offre de cours en ligne et le développement des compétences. Rassembler toutes les personnes impliquées dans ce domaine au même endroit, afin de réduire les coûts et d’augmenter la qualité, l’efficacité, la facilité et la transparence. Ainsi, que tout le monde puisse apprendre et gagner, peu importe où il en est physiquement et dans son cheminement de carrière.",
        aboutVisionTitle: "Vision",
        aboutVision: "Fournir des compétences à tous ceux qui cherchent à exceller dans leur domaine. pour devenir un symbole de confiance, de transparence et d’espoir pour tous. Aider chacun à réaliser ses rêves en lui fournissant l’éducation et les ressources appropriées.",
        aboutContactTitle: "Contactez-nous",
        aboutContactButton: "Envoyez-nous un courriel",
        aboutSocialButton: "Connecte-toi avec nous",

        footerViewJobs: "Afficher les offres d’emploi",
        footerViewCompanies: "Voir les entreprises",
        footerGetCertificated: "Obtenir un certificat",
        footerAbout: "Environ",
        footerPostJob: "Offres d’emploi",
        footerWorkAtBaara: "Travailler chez Baara",

        launchTitle: "Trouver un emploi chez BAARA",
        launchWhatToSearch: "Qu’est-ce que tu cherches?",
        launchWhereToSearch: "Où cherchez-vous un emploi?",
        launchSearchButton: "Find Jobs",
        launchWhatTextbox: "Job Title, Company Name, or Tag",
        launchWhereTextbox: "City, Zip code, or \"online\"",
        launchPostJob: "Are you an Employer?",
        launchPostJobButton: "Post a Job",

        hireTitle: "Publiez des offres d’emploi pour trouver les employés parfaits",
        hireSubtitle: "Contactez-nous pour ajouter votre entreprise et vos emplois à notre site",
        hireButton: "Soumettre le formulaire",

        certificatesTitle: "Obtenez la certification",
        certificatesViewLearnMore: "Pour en savoir plus",

        jobFilterRecent: "Récent",
        jobFilterLoved: "Sauvé",

        jobFilterRecommend: "Recommander",
        jobsFilterNoSavedJobs: "Aucune tâche sauvegardée",
        jobsFilterSignIn: "Vous devez d’abord vous connecter",
        jobsFilterNoRecommend: "Pas encore d’emplois à recommander",
        
        loading: "Chargement..."
    }
    
   
};
i18n.locale = Localization.locale;

i18n.fallbacks = true;