import React, {useState, useRef, createRef} from "react";
import {TextInput, View, Text, StyleSheet, TouchableOpacity, Modal, Pressable, ScrollView, SafeAreaView, TouchableWithoutFeedback, Linking} from "react-native"
import isMobile from "../Modules/isMobile";
import { Ionicons } from '@expo/vector-icons';
import i18n from 'i18n-js';
import {addReviewToCompany, updateReviewToCompany} from "../Modules/Firebase"
import CustomButton from "./CustomButton";
import {onAuthStateChanged, getAuth} from "firebase/auth";
import StarPicker from "./StarPicker";
export default function AddReviewView({data, visible, hideModal, navigation, id, setToast, reviewData}) {
    const [isSignedIn, setSignedIn]  = useState(false);
    const [stars, setStars]  = useState(-1);
    const [review, setReview]  = useState("");
    
    if(reviewData && stars == -1){
      setStars(reviewData.stars);
      setReview(reviewData.review);
    }else if(stars == -1){
      setStars(0);
    }
    onAuthStateChanged(getAuth(), (user) => {
        if (user) {
          setSignedIn(true);
        } else {
          setSignedIn(false);
        }
    });
    function reviewUpdateCallback(event){
      if(event.isError){
        setToast(event.error);
      }else if(event.review){
        setToast(i18n.t("addReviewUpdatedReview"));
        hideModal(event.review, event.oldReview);
      }
    }
    function reviewAddCallback(event){
      if(event.isError){
        setToast(event.error);
      }else if(event.review){
        setToast(i18n.t("addReviewSubmitedReview"));
        hideModal(event.review);
      }
    }
    return(
        <Modal animationType="slide"
            transparent={true}
            visible={visible}>
            <TouchableOpacity style={styles.modalBackground} onPress={hideModal}>
                
                {isSignedIn? <TouchableWithoutFeedback>
                  <View style={styles.modalView}>
                    <ScrollView contentContainerStyle={{justifyContent:"flex-start", alignContent:"center", flex:1}}>
                        <Text style={styles.reviewTitle}>{reviewData?i18n.t("addReviewUpdateReviewTitle"):i18n.t("addReviewAddReviewTitle")}</Text>
                        <StarPicker stars={stars} onStarsEdit={(star) => setStars(star)} />
                        <TextInput value={review} onChangeText={setReview} multiline={true} style={styles.reviewTextBox} />
                        {reviewData? 
                          <CustomButton text={i18n.t("addReviewUpdateReviewButton")} onPress={()=>{
                              if(stars == 0){
                                setToast(i18n.t("addReviewAlertStarReview"));
                              }else if(review === ""){
                                setToast(i18n.t("addReviewAlertBlankReview"));
                              }else{
                                updateReviewToCompany(review, stars, reviewData, id, data.name, reviewUpdateCallback)
                              }
                               
                          }} />
                        :
                          <CustomButton text={i18n.t("addReviewSubmitReviewButton")} onPress={()=>{
                              if(stars == 0){
                                setToast(i18n.t("addReviewAlertStarReview"));
                              }else if(review === ""){
                                setToast(i18n.t("addReviewAlertBlankReview"));
                              }else{
                                addReviewToCompany(review, stars, id, data.name, reviewAddCallback)
                              }
                              
                          } 
                          } />
                        }
                        
                    </ScrollView>
                  </View>
                </TouchableWithoutFeedback>:
                <View style={styles.modalView}>
                  <Text style={{marginBottom: 10}}>{i18n.t("addReviewMustBeSignedInWarn")}</Text>
                  <CustomButton text={i18n.t("addReviewSignInButton")} onPress={()=>{
                    hideModal();
                    navigation.navigate("Account")
                  }} />
                </View>}
              
            </TouchableOpacity>
        </Modal>
    )
    
}

const styles = StyleSheet.create({
    modalBackground:{
      flex:1,
      backgroundColor:"rgba(0, 0, 0, 0.42)",
      height: "100%",
      width: "100%",
      alignItems: 'center',
        justifyContent: 'center',
        
    },  
    modalView:{
        opacity: 1,
        borderRadius: 20,
        alignSelf:"center",
        justifyContent: 'center',
        padding: 10,
        height: "70%",
        width: "90%",
        textAlign:"center",
        backgroundColor:"lightgray"
    },
    reviewTitle:{
        fontSize: 30,
        fontWeight: "bold",
        marginTop: 50,
    },
    reviewTextBox:{
        maxWidth: 400,
        minWidth: 300,
        alignSelf: "center",
        marginVertical: 50,
        minHeight: 60,
        backgroundColor: "white",
        borderRadius: 10,
        padding: 5,
    }
    
    
});