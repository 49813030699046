import React, {useState} from "react";
import { View, Text, StyleSheet , TouchableOpacity} from "react-native";
import { Ionicons } from '@expo/vector-icons';
export default function CompanyJobList({onDelete, jobs, navigation, onPress}) {
    const wordCountDescription = 20;
    if(jobs){
        return jobs.map((job, index) => {
            return (
                
                <TouchableOpacity onPress={() =>{
                    if(onPress){
                        onPress(job)
                    }else{
                        navigation.navigate('JobPage', {id:job.id});
                    }
                    
                }}>
                    <View style={styles.jobView}>
                        {onDelete?
                        <View style={styles.trashView}>
                            <TouchableOpacity onPress={()=>onDelete(job, index)}>
                                <Ionicons size={25} name={"trash-outline"} />
                            </TouchableOpacity>
                        </View>
                        :undefined}
                        <Text style={styles.titleText}>{job.title}</Text> 
                        {job.location? <Text style={styles.locationText}>{job.location}</Text> : undefined }
                        {job.longDescription? <Text style={styles.longDescriptionText}>{job.longDescription.split(/\s+/).slice(0,wordCountDescription).join(" ") + "..."}</Text> : undefined }
                    </View>
                </TouchableOpacity>
             )
        });
    }else{
        return(
            <View style={styles.jobView}>
                <Text>No Jobs Found</Text> 
            </View>
        )
    }
   
}

const styles = StyleSheet.create({
    
    trashView:{
        flexDirection: "row",
        justifyContent: "flex-end",
        flex:1,
    },
    jobView:{
        backgroundColor: "white",
        borderColor:"black",
        borderWidth:2,
        padding: 15,
        borderRadius: 20,
        width: 350,
        marginBottom: 20,
    },
    titleText:{
        textAlign:"center",
        fontSize: 20,
        fontWeight: "bold",
    },
    locationText:{
        textAlign:"center",
        fontSize: 15,
        marginBottom: 10,
    },
    longDescriptionText:{
        fontSize: 12,
    },
});