import React, {useState} from "react";
import {View, Text, StyleSheet, TouchableOpacity, Pressable, ScrollView, SafeAreaView} from "react-native"
import isMobile from "../Modules/isMobile";
import { Ionicons } from '@expo/vector-icons';
import i18n from 'i18n-js';
export default function SearchResult({text, clearSearch}) {
    var searchText = text;
    if(searchText.length > 15) searchText = searchText.substring(0,15)+"...";
    return(
        <View style={styles.container}>
            <Text style={styles.searchTitle}>Showing results for: {searchText}</Text>
            <TouchableOpacity style={styles.closeButton} onPress={clearSearch}>
                <Ionicons size={30} name="close-outline" />
            </TouchableOpacity>
        </View>
    )   
    
}

const styles = StyleSheet.create({
    container:{
        flex:1,
        alignItems: 'center',
        justifyContent: 'space-between',
        maxHeight: 60,
        flexDirection: "row",
        borderBottomWidth: 2,
        borderBottomColor: "black",
    },  
    
    searchTitle:{
      fontSize: 20,
      marginBottom: 3,
      fontWeight:"bold",
      textAlign:"center",
      marginHorizontal: 10,
    },

    closeButton:{
        marginLeft: 20,
    }
    
});