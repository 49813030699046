import React, {useState} from "react";
import { View, TextInput, SafeAreaView, Text, StyleSheet, ScrollView, Platform } from "react-native";
import CustomButton from "../Components/CustomButton";
import {contactBara} from "../Modules/GlobalFunctions";
import i18n from "i18n-js";
import Footer from "../Components/Footer";
export default class LaunchPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            whatJob:"",
            whereJob:"",
        };
    }

    render(){
        
        return(
            <SafeAreaView style={{flex:1}}>
                <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }} style={{flex:1}}>
                    
                    <Text style={styles.title}>{i18n.t("launchTitle")}:</Text>
                    <Text style={styles.subtitle}>{i18n.t("launchWhatToSearch")}</Text>
                    <TextInput onChangeText={(text)=>this.setState({whatJob:text})} value={this.state.whatJob} placeholder={i18n.t("launchWhatTextbox")} style={styles.searchTextBox} />
                    <Text style={styles.subtitle}>{i18n.t("launchWhereToSearch")}</Text>
                    <TextInput style={styles.searchTextBox} onChangeText={(text)=>this.setState({whereJob:text})} value={this.state.whereJob} placeholder={i18n.t("launchWhereTextbox")}  />
                    <View style={{marginTop:20}}/>
                    <CustomButton text={i18n.t("launchSearchButton")} onPress={()=>  {
                        var searchText ="";
                        if(this.state.whereJob){
                            searchText = "where:"+this.state.whereJob+" ";
                        }
                        if(this.state.whatJob){
                            searchText = searchText+this.state.whatJob;
                        }
                        this.props.navigation.navigate("Jobs", {search:searchText})
                    } }/>

                    <View style={{marginTop:50}}/>
                    <Text style={styles.subtitle}>{i18n.t("launchPostJob")}</Text>
                    <View style={{marginTop:20}}/>
                    <CustomButton text={i18n.t("launchPostJobButton")} onPress={()=>  this.props.navigation.navigate("Hire")} />
                    {Platform.OS == "web"? 
                        <Footer navigation={this.props.navigation}/>
                    : undefined}
                </ScrollView>
            </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({
    container:{
        flex:1,
        justifyContent:"center",
        alignContent:"center",
    },
    title:{
        fontWeight:"bold",
        fontSize: 35,
        textAlign:"center",
        marginTop: 10,
    },
    subtitle:{
        fontWeight:"bold",
        fontSize: 20,
        textAlign:"center",
        marginTop: 20,
    },
    searchTextBox:{
        marginTop:10,
        backgroundColor:"lightgray",
        width:"50vw",
        maxWidth: 500, 
        height: 40,
        alignSelf:"center",
        borderRadius: 10,
        paddingHorizontal:10,
    }

    
})