import React, {useState} from "react";
import { View, FlatList, SafeAreaView, Text, StyleSheet, Linking, Platform } from "react-native";
import {getJob, getCompany, getUserData, addSavedJob, removeSavedJob} from "../Modules/Firebase"
import CustomButton from "../Components/CustomButton";
import ApplyView from "../Components/ApplyView";
import {jobTypeConvert, containsId} from "../Modules/GlobalFunctions"
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import QualificationsView from "../Components/QualificationsView";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import ShowToast from "../Modules/ShowToast";
import Footer from "../Components/Footer";
import { Ionicons } from '@expo/vector-icons';
import {checkDataForTranslation} from "../Modules/GlobalFunctions";
import SaveForLater from "../Components/SaveForLater"
import i18n from "i18n-js";
var myThis;
export default class JobPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data: null,
            companyData:null,
            showApply: false,
            signedIn: null,
            userData:null,
            toastMessage: "",
            jobId: (props.route && props.route.params && props.route.params.id)?  props.route.params.id : props.jobId,
        }
        myThis = this;
    }
    updateJob(){
        if(this.state.jobId != null){
            getJob(this, "data", this.state.jobId);
        }
    }
    componentDidLoad(){
        
        
    }
    
    componentDidUpdate(){
        if(this.props.route?.params?.id && this.props.route.params.id != this.state.jobId){
            this.setState({jobId: this.props.route.params.id, data: null})
            this.updateJob();
        }
        if(this.props.jobId && this.props.jobId != this.state.jobId){
            this.setState({jobId: this.props.jobId, data: null})
            this.updateJob();
        }
        
    }
    render(){
        
        if(this.state.signedIn == null){
            onAuthStateChanged(getAuth(), (user) => {
                if (user) {
                  this.setState({signedIn: true});
                } else {
                    this.setState({signedIn: false});
                }
            });
        }
        if(this.state.data == null){
            this.updateJob();
        }
        
        if(this.state.userData == null && this.state.signedIn == true){
            getUserData(this, "userData");
        }
        if(this.state.data && this.state.data.companyId && this.state.companyData == null){
            getCompany(this, "companyData", this.state.data.companyId);
        }
        function saveForLater(){
            if(myThis.state.userData && myThis.state.userData.saveForLater &&  myThis.state.userData.saveForLater.includes(myThis.state.jobId)){
                removeSavedJob(myThis.state.jobId, (e)=>{
                    if(e && e.error){
                        myThis.setState({toastMessage:e.error});
                    }else{
                        var tempData =  myThis.state.userData;
                        tempData.saveForLater = tempData.saveForLater.filter(function(item) {
                            return item !== myThis.state.jobId;
                        });
                        myThis.setState({userData:tempData});
                    }
                });
            }else{
                addSavedJob(myThis.state.jobId, (e)=>{
                    if(e && e.error){
                        myThis.setState({toastMessage:e.error});
                    }
                });
            }
        }
        return(

            <SafeAreaView style={{flex:1, backgroundColor:"white"}}>
                {/* External Window for Split View */}
                {this.props.splitView && this.props.jobId?
                    <View style={{alignSelf:"flex-end"}}>
                    <TouchableOpacity onPress={()=>this.props.navigation.navigate('JobPage', {id:this.props.jobId})}>
                        <Ionicons name="open-outline" size={30} />
                    </TouchableOpacity>
                    </View>
                    : 
                    undefined
                }
                <ShowToast message={this.state.toastMessage} didShow={()=>this.setState({toastMessage:""})}/>
                <ApplyView data={this.state.data} setToast={(toast:String) => this.setState({toastMessage:toast})} id={this.state.jobId} visible={this.state.showApply} navigation={this.props.navigation} hideModal={()=>this.setState({showApply: false})}/>
                {this.state.data && this.state.companyData?
                    <ScrollView contentContainerStyle={styles.jobBg} style={{flex:1, alignSelf:"center"}} scrollEnabled={this.state.showApply == false}>
                        <Text style={styles.jobTitle}>{this.state.data?.title? this.state.data?.title : undefined}</Text> 
                        <View style={{flexDirection:"row"}}>
                            <Text style={{ fontSize:20, marginBottom: 8}}>Company:</Text>
                            <TouchableOpacity onPress={()=>{
                                if(this.state.data.companyId){
                                    this.props.navigation.navigate('CompanyPage', {id:this.state.data.companyId});
                                }
                            }}> 
                                <Text style={styles.jobCompany}>{this.state?.companyData?.name? this.state.companyData?.name : undefined}</Text>
                            </TouchableOpacity>
                        </View>
                        <TouchableOpacity onPress={()=>{
                            if(this.state.data?.location){
                                Linking.openURL("https://maps.google.com/?q="+this.state.data?.location);
                            }
                            
                        }}> 
                            <Text style={styles.jobLocation}>{this.state.data?.location? this.state.data.location : undefined}</Text>
                        </TouchableOpacity>
                        
                        <Text style={styles.jobSalary}>{this.state.data?.salary? "Salary:"+this.state.data.salary : undefined}</Text> 
                        <Text style={styles.jobType}>{this.state.data?.jobType? "Type:"+jobTypeConvert(this.state.data.jobType) : undefined}</Text> 
                        <View style={{flexDirection:"row"}}>
                            <CustomButton text={"Apply Now"} onPress={()=>{
                                if(this.state.userData && this.state.userData.jobsApplied && containsId(this.state.userData.jobsApplied, this.state.jobId)){
                                    this.setState({toastMessage: "Already Applied to job"});
                                }else if(this.state.userData && this.state.userData.owner){
                                    this.setState({toastMessage: "Owner cannot apply to job"});
                                }
                                else{
                                    this.setState({showApply: true});
                                }
                                
                            }}/>
                            <SaveForLater id={this.state.jobId} userData={this.state.userData} onPress={saveForLater} />
                        </View>
                        <Text style={styles.jobQualificationsTitle}>{this.state.data?.qualifications? "Qualifications:" : undefined}</Text> 
                        {this.state.data?.qualifications? <QualificationsView qualifications={this.state.data?.qualifications} /> :undefined}
                        <Text style={styles.jobDescriptionTitle}>{this.state.data?.longDescription? "Description:" : undefined}</Text> 
                        <Text style={styles.jobDescription}>{this.state.data?.longDescription? this.state.data.longDescription : undefined}</Text> 
                        <View style={{padding:10}}></View>
                        {Platform.OS == "web" && this.props.splitView != true?
                            <Footer navigation={this.props.navigation}/>
                        : undefined}
                    </ScrollView>
                    
                :
                <>
                <Text>{this.props.splitView != true? i18n.t("loading"):undefined}</Text>
                {Platform.OS == "web" && this.props.splitView != true? 
                    <Footer navigation={this.props.navigation} />
                : undefined}
                </>
                }
                

            </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({
    jobBg:{
        alignItems:"center", 
        marginHorizontal:10, 
        backgroundColor:"white",
    },
    jobTitle:{
      fontSize: 30,
      marginVertical: 10,
      fontWeight:"bold",
      textAlign:"center",
    },
    jobCompany:{
        textDecorationLine: 'underline',
        fontSize: 20,
        color:"blue",
        marginBottom: 8,
    },
    jobLocation:{
        textDecorationLine: 'underline',
        fontSize: 20,
        color:"blue",
        marginBottom: 8,
    },
    jobSalary:{
        fontSize: 18,
        marginBottom: 8,
    },
    jobType:{
        fontSize: 18,
        marginBottom: 15,
    },
    jobQualificationsTitle:{
        marginTop: 30,
        fontSize: 18,
        marginBottom: 10,
    },
    jobDescriptionTitle:{
        fontSize: 18,
        marginBottom: 10,
    },
    jobDescription:{
        fontSize: 13,
        textAlign:"center",
        paddingHorizontal:20,
        paddingVertical:10,
        backgroundColor:"white",
        borderRadius: 25,
        borderWidth:2,
        borderColor:"black",
        maxWidth:600,
    },
    
});