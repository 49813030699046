import { registerRootComponent } from 'expo';
import 'react-native-reanimated';
import './Modules/Localization';
import Navigaitor from './Pages/Navigator';
import { ToastProvider } from 'react-native-toast-notifications'
import { StatusBar, Platform } from 'react-native';
export default function App() {
  return (
    
    <ToastProvider>
      <StatusBar
        hidden={true} />
      <Navigaitor/>
    </ToastProvider>
    
  );
}

registerRootComponent(App);