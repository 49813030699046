import React, {useState} from "react";
import { View, Text, StyleSheet , TextInput, TouchableOpacity} from "react-native";
import { Ionicons } from '@expo/vector-icons';
import { getAuth } from "firebase/auth";
import i18n from 'i18n-js';
import {verfiyAction} from "../Modules/GlobalFunctions"
import {removeReviewFromCompany} from "../Modules/Firebase"
export default function Reviews({reviews, updateReviews, id, updateReview}) {
    if(reviews && reviews.length > 0){
        return reviews.map(review => {
            
            return (
                <TouchableOpacity disabled={review.userId != getAuth().currentUser?.uid} onPress={()=>updateReview(review)}>
                    <View style={styles.reviewView}>
                        {getAuth().currentUser && getAuth().currentUser.uid === review.userId? 
                            <View style={styles.trashView} >
                                <TouchableOpacity  onPress={()=>{
                                    verfiyAction(i18n.t("reviewsDeleteReview"), (e)=>{
                                        if(e.verify == true){
                                            removeReviewFromCompany(review, id, updateReviews);
                                        }
                                    })
                                    
                                }}>
                                    <Ionicons size={25} name={"trash-outline"} />
                                </TouchableOpacity>
                            </ View>
                        : 
                        undefined}
                        <Text style={styles.byText}>By:{review.userId === getAuth().currentUser?.uid? i18n.t("reviewsYouText"):undefined}{review.user}</Text> 
                        <View style={styles.starView}>
                            <Ionicons size={20} name={review.stars >= 1? "star":"star-outline"} />
                            <Ionicons size={20} name={review.stars >= 2? "star":"star-outline"} />
                            <Ionicons size={20} name={review.stars >= 3? "star":"star-outline"} />
                            <Ionicons size={20} name={review.stars >= 4? "star":"star-outline"} />
                            <Ionicons size={20} name={review.stars >= 5? "star":"star-outline"} />
                        </View>
                        <Text style={styles.reviewText}>Review:{review.review}</Text> 
                    </View>
                </TouchableOpacity>
             )
        });
    }else{
        return(
            <View style={styles.reviewView}>
                <Text>No Reviews Found</Text> 
            </View>
        )
    }
   
}

const styles = StyleSheet.create({
    
    
    reviewView:{
        backgroundColor: "white",
        padding: 20,
        borderRadius: 20,
        width: 350,
        marginBottom: 20,
        borderWidth:2,
        borderColor:"black"
    },
    trashView:{
        flexDirection: "row",
        justifyContent: "flex-end"
    },
    starView:{
        paddingTop: 10,
        flexDirection: "row",
        justifyContent: "center"
    },
    byText:{
        textAlign:"center",
        fontSize: 15,
    },
    reviewText:{
        paddingTop: 10,
        fontSize: 15,
    },
});