import i18n from "i18n-js";
import { Linking, Platform, Alert } from "react-native";
import * as Localization from 'expo-localization';
export const jobTypeConvert = (jobType)=>{
    if(jobType === "full"){
        return i18n.t("jobTypeFull");
    }else if(jobType === "part"){
        return i18n.t("jobTypePart");
    }
    return "n/a"
}
export const qualificationTypeConvert = (qalificationType)=>{
    if(jobType === "full"){
        return i18n.t("jobTypeFull");
    }else if(jobType === "part"){
        return i18n.t("jobTypePart");
    }
    return "n/a"
}

export const viewFile = (fileUrl)=>{
    if(typeof fileUrl === "string"){
        Linking.openURL(fileUrl);
      }else{
        Linking.openURL(URL.createObjectURL(fileUrl));
      }
}

export const containsId = (myarray:Array<Object>, id)=>{
    let contains = false;
    myarray.forEach(element => {
        if(element.id===id){
            contains = true;
        }
    });
    return contains;
}
export const containsOwnerId = (myarray:Array<Object>, id)=>{
    let contains = false;
    myarray.forEach(element => {
        if(element===id){
            contains = true;
        }
    });
    return contains;
}
export const containsUserId = (myarray:Array<Object>, id)=>{
    let contains = false;
    myarray.forEach(element => {
        if(element.userId && element.userId===id){
            contains = true;
        }
    });
    return contains;
}
export const verfiyAction = (text, callback)=>{
    if(Platform.OS == "web"){
        if (confirm(text)) {
            callback({verify:true})
          } else {
            callback({verify:false})
          }
    }else{
        Alert.alert(
            i18n.t("globalFunctionsVerifyAction"),
            text,
            [
              {
                text: i18n.t("globalFunctionsVerifyActionCancel"),
                onPress: () => callback({verify:false}),
                style: "cancel"
              },
              { text: i18n.t("globalFunctionsVerifyActionOk"), onPress: () => callback({verify:true}) }
            ]
          );
    }
}
export const contactBara = ()=>{
    Linking.openURL("mailto:lefaster97@gmail.com");
}
export function removeItem<T>(arr: Array<T>, value: T): Array<T> { 
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

function parseJSONToCSVStr(jsonData) {
    if(jsonData.length == 0) {
        return '';
    }

    let keys = Object.keys(jsonData[0]);

    let columnDelimiter = ',';
    let lineDelimiter = '\n';

    let csvColumnHeader = keys.join(columnDelimiter);
    let csvStr = csvColumnHeader + lineDelimiter;

    jsonData.forEach(item => {
        keys.forEach((key, index) => {
            if( (index > 0) && (index < keys.length-1) ) {
                csvStr += columnDelimiter;
            }
            csvStr += item[key];
        });
        csvStr += lineDelimiter;
    });

    return encodeURIComponent(csvStr);;
}
export function exportToCsvFile(jsonData) {
    let csvStr = parseJSONToCSVStr(jsonData);
    let dataUri = 'data:text/csv;charset=utf-8,'+ csvStr;

    let exportFileDefaultName = 'data.csv';

    let linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
}
export function checkDataForTranslation(data) {
    var trans = i18n.locale;
    if(trans.includes("-")){
        trans = trans.split("-")[0];
    }
    if(trans == "en"){ //English is default lang
        return data;
    }else if(data.language && data.language[trans]){
        return data.language[trans];
    }else{
        return null;
    }
}
export function getRegion() {
    return "bf";
}
export function getLocation() {
    return "";
}