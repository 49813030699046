import React, {useState} from "react";
import { View, FlatList, SafeAreaView, Dimensions, ScrollView, StyleSheet, Platform } from "react-native";
import CompanyListItem from "../Components/CompanyListItem";
import {getCompanies} from "../Modules/Firebase";
import SearchResult from "../Components/SearchResult";
import CompanyPage from "./CompanyPage";
import Footer from "../Components/Footer";
export default class Companies extends React.Component{
    constructor(props){
        super(props);

        this.state ={
            companies:[{name:"Loading...", id:"3210"}],
            search: (props.route.params && props.route.params.search)?  props.route.params.search : null,
            isMobile: null,
            companyId: null,
        }
    }
    componentDidMount(){
        if(Platform.OS === "web"){
            if(this.state.isMobile == null){
                if(Dimensions.get("window").width > 800){ 
                    this.setState({isMobile:false});
                }else{
                    this.setState({isMobile:true});
                }
            }
            Dimensions.addEventListener(
                "change",
                ({ window, screen }) => {
                    if(window.width > 800){
                        this.setState({isMobile:false});
                        
                    }else{
                        this.setState({isMobile:true});
                    }
                    
                }
              );
        }
    }
    searchCompanies(){
        getCompanies(this, "companies", this.props.route?.params?.search);
    }
    componentDidUpdate(){
        if(this.props.route?.params?.search && this.props.route.params.search != this.state.search){
            this.setState({search: this.props.route.params.search})
            this.searchCompanies()
        }
        if(this.state.search && (this.props.route?.params == null || this.props.route?.params.search == null )){
            this.setState({search: null})
            this.searchCompanies()
        }
        
    }
    render(){
        if(this.state.isMobile == null){
            if(Dimensions.get("window").width > 800){ 
                this.setState({isMobile:false});
            }else{
                this.setState({isMobile:true});
            }
        }
        if(this.state.companies[0] && this.state.companies[0].name  === "Loading..."){
            this.searchCompanies()
        }else if(this.state?.companies && this.state?.companies[0] && this.state?.companies[0].id && this.state.companyId == null){
            
            this.setState({companyId:this.state?.companies[0].id});
        }
        var navigation = this.props.navigation;
        const renderItem = ({ item }) => (
            <CompanyListItem data={item} companyId={this.state.companyId} isMobile={this.state.isMobile} name={item.name} about={item.about} onPress={()=>{
                if(this.state.isMobile){
                    navigation.navigate('CompanyPage', {id:item.id});
                }else{
                    this.setState({companyId: item.id});
                }
            }} />
        );
        
        return(
            <SafeAreaView style={{flex:1}}>
                <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }} style={{flex:1, alignSelf:"center"}}>
                    {this.state.search?
                        <SearchResult text={this.state.search} clearSearch={() => {
                            this.props.navigation.navigate('Companies');
                            
                        }} />
                        :
                        undefined

                    }
                    {this.state.isMobile? 
                        <FlatList 
                        style={styles.companiesList}
                        contentContainerStyle={[{ flexGrow: 1, flex:1 }, styles.listOfCompanies]}
                        data={this.state.companies}
                        renderItem={renderItem}
                        keyExtractor={item => item.id}
                        />
                        
                    :
                        <View style={[{flexDirection:"row", flex: 1, maxWidth: 800,  alignSelf:"center"}, styles.companiesList]}>
                            <FlatList 
                            style={{maxWidth: 400}}
                            contentContainerStyle={[{ flex:1, flexGrow: 1, maxWidth: 400 }, styles.listOfCompanies]}
                            data={this.state.companies}
                            renderItem={renderItem}
                            keyExtractor={item => item.id}
                            />
                            <ScrollView style={styles.splitView}>
                                <CompanyPage navigation={this.props.navigation} splitView={true} companyId={this.state.companyId} />
                            </ScrollView>
                        </View>
                    }
                    
                </ScrollView>
            </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({
    splitView:{
        borderColor:"lightgray",
        borderWidth: 1,
        flex:1,
        borderRadius:10,
        backgroundColor:"white",
        minWidth: 400,
    },
    listOfCompanies:{
        marginRight:10,
    },
    companiesList:{
        marginHorizontal:30,
        marginTop:30,
        marginBottom:10,
        
    }
})