import React, {useState} from "react";
import { View, Text, StyleSheet , TextInput, Linking} from "react-native";
import CustomButton from "./CustomButton";
import i18n from "i18n-js";
export default function CertificateView({data}) {
    return(
        <View style={styles.certificateView}>
            <Text style={styles.title}>{data.name}</Text> 
            {data.name && data.name != i18n.t("loading")? 
                <>
                <Text style={styles.by}>By:{data.by}</Text> 
                <Text style={styles.description}>{data.description}</Text>
                <View style={{marginTop:10}}/> 
                <CustomButton text={i18n.t("certificatesViewLearnMore")} fontSize={10} onPress={()=>{
                    Linking.openURL(data.url);
                }}/>
                </>
                : undefined
            }
            
        </View>
    )
   
}

const styles = StyleSheet.create({
    
    
    certificateView:{
        backgroundColor: "white",
        padding: 20,
        borderRadius: 20,
        marginBottom: 20,
        margin: 10,
        borderWidth:2,
        borderColor:"#800080"
        
    },
    title:{
        fontSize:20,
        fontWeight:"bold",
        textAlign:"center",
        marginVertical:2,
    },
    by:{
        fontSize:18,
        fontWeight:"bold",
        textAlign:"center",
        marginVertical:2,
    },
    description:{
        fontSize:15,
        textAlign:"center",
        marginVertical:2,
    }
    
});