import React, {useState, useEffect} from "react";
import { View, Text, Image, StyleSheet, TextInput, Linking, ScrollView } from "react-native";
import i18n from "i18n-js";
import CustomButton from "./CustomButton";
import * as DocumentPicker from 'expo-document-picker';
import {onAuthStateChanged, getAuth} from "firebase/auth";
import {logOut, myUser, sendVerification, setDisplayName, uploadCompanyFile, deleteJob, updateCompany, getCompany, getCompanyJobs} from "../Modules/Firebase"
import { useToast } from "react-native-toast-notifications";
import CompanyJobList from "./CompanyJobList"
import AddJob from "./AddJob";
import {verfiyAction, removeItem} from "../Modules/GlobalFunctions"
export default function OwnerView({setToast, navigation, ownerId, onSignOut}){
    const [name, setName] = useState(getAuth()?.currentUser?.displayName);
    const [jobs, setJobs] = useState(null);
    const [jobId, setJobId] = useState(null);
    const [company, setCompany] = useState(null);
    const [showAddJob, setShowAddJob] = useState(false);
    if(jobs == null){
        getCompanyJobs(setJobs, null, ownerId, true);
    }
    if(company == null){
        getCompany(setCompany, null, ownerId);
    }
    function updateCompanyData(event){

    }
    function uploadCompanyPhotoCallback(event){

    }
    async function uploadCompanyPhoto(){
        const results = await DocumentPicker.getDocumentAsync({copyToCacheDirectory:true, type:["image/png"]});
        
        if(results?.type == "success" && results?.uri){
            const r = await fetch(results.uri);
            const b = await r.blob();
            uploadCompanyFile(ownerId, b, uploadCompanyPhotoCallback, "logo.png");
        }
    }
    
    return(
        <>
            <AddJob setToast={setToast} companyId={ownerId} visible={showAddJob} id={jobId} hideModal={(reload) => {
                setShowAddJob(false);
                setJobId(null);
                if(reload){
                    setJobs(null);
                }
            }} />
            <ScrollView style={styles.container}>
                <Text style={styles.title}>{i18n.t("ownerViewTitleInfo")}:</Text>
                <Text style={styles.secondaryTitle}>{i18n.t("ownerViewDisplayNameTitle")}:</Text>
                <TextInput 
                style={styles.displayNameTextInput} 
                placeholder={"name"} 
                value={name? name: ""}
                returnKeyType={"done"}
                onSubmitEditing={() => {
                    if(name && name != ""){
                        setDisplayName(name, setNameCallback)
                    }
                }}
                onBlur={() => {
                    if(name && name != ""){
                        setDisplayName(name, setNameCallback)
                    }
                }}
                onChangeText={text => setName(text)} />
                <Text style={styles.secondaryTitle}>{i18n.t("ownerViewCompanyNameTitle")}:</Text>
                <TextInput 
                style={styles.displayNameTextInput} 
                placeholder={"name"} 
                value={company && company.name? company.name: ""}
                returnKeyType={"done"}
                onSubmitEditing={() => {
                    if(company.name && company.name != ""){
                        updateCompany(company, ownerId, updateCompanyData)
                    }
                }}
                onBlur={() => {
                    if(company.about && company.about != ""){
                        updateCompany(company, ownerId, updateCompanyData)
                    }
                }}
                onChangeText={text => setCompany({...company, name:text})} />
                <Text style={styles.secondaryTitle}>{i18n.t("ownerViewCompanyAboutTitle")}:</Text>
                <TextInput 
                multiline={true}
                style={[styles.displayNameTextInput, {height: 70}]} 
                placeholder={"name"} 
                value={company && company.about? company.about: ""}
                returnKeyType={"done"}
                onSubmitEditing={() => {
                    if(company.about && company.about != ""){
                        updateCompany(company, ownerId, updateCompanyData)
                    }
                }}
                onBlur={() => {
                    if(company.about && company.about != ""){
                        updateCompany(company, ownerId, updateCompanyData)
                    }
                }}
                onChangeText={text => setCompany({...company, about:text})} />
                <CustomButton text={i18n.t("ownerViewAddPhotoButton")} onPress={() =>{
                    uploadCompanyPhoto();
                }}/>
                <CustomButton text={i18n.t("ownerViewCompanyPageButton")} onPress={() =>{
                    navigation.navigate('CompanyPage', {id:ownerId});
                }}/>
                
                <Text style={styles.secondaryTitle}>{i18n.t("ownerViewJobsPosted")}:</Text>
                <CompanyJobList onDelete={(job, jobIndex)=>{
                    verfiyAction(i18n.t("ownerViewDeleteJobWarning")+" \""+job.title+"\"", (e)=>{
                        if(e.verify == true){
                            deleteJob(job.id, (e)=>{
                                if(e.dataSet){
                                    var tempData = [...jobs];
                                    tempData.splice(jobIndex,1);
                                    setJobs(tempData);
                                }
                            })
                        }
                    })
                }} jobs={jobs} onPress={(job) =>{
                    setJobId(job.id);
                    setShowAddJob(true);
                }} />
                <CustomButton text={i18n.t("ownerViewAddNewJob")} onPress={() =>{
                    setJobId("new");
                    setShowAddJob(true);
                }}/>
                <View style={{marginBottom:20}} />
                <CustomButton text={i18n.t("accountViewSignOutButton")} onPress={() =>{
                    onSignOut();
                }}/>
            </ScrollView>
        </>
    )
}

const styles = StyleSheet.create({
    container:{
        flex:1,
        alignSelf:"center",
    },
    title:{
        marginBottom:20, 
        fontSize: 20,
        fontWeight:"bold",
        textAlign:"center",
    },
    secondaryTitle:{
        marginBottom:5,
        marginTop: 15,
        fontSize: 15,
        fontWeight:"bold",
        textAlign:"left",
    },
    labelText:{
        fontSize:12,
        marginBottom:5, 
        textAlign:"center",
    },
    displayNameTextInput:{
        marginBottom: 20,
        marginHorizontal: 5,
    },
    
})