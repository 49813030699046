import React, {useState} from "react";
import { View, Text, StyleSheet , TextInput} from "react-native";
import { Ionicons } from '@expo/vector-icons';

export default function QualificationsView({qualifications}) {
    
    if(qualifications){
        
        return qualifications.map(qualification => {
            return (
                <View style={styles.skillView}>
                    
                    <Text>{qualification.qualification}</Text> 
                    <Text>({qualification.type})</Text> 
                    
                </View>
             )
        });
    }else{
        return(
            <>
            </>
        )
    }
   
}

const styles = StyleSheet.create({
    
    skillView:{
        padding: 5,
        flexDirection:"row",
        marginBottom: 5,
    },
    skillText:{
        fontSize: 13,
    },
});