import React, {useState} from "react";
import {View, Text, StyleSheet, TouchableHighlight} from "react-native"
import isMobile from "../Modules/isMobile";
import { Ionicons } from '@expo/vector-icons';
import i18n from 'i18n-js';

export default function JobsFilter({data, handleFilter, userData}) {
    const [jobFilter, setJobFilter] = useState("recent");
    function filterData(filterBy:string){
        handleFilter(filterBy);
    };
    return( <View style={styles.segmentContainer}>
        
                <TouchableHighlight onPressOut={() =>{
                    setJobFilter("recent");
                    filterData("recent");
                } }>
                    <Text style={jobFilter === "recent"?  styles.accountTitleActive : styles.accountTitle}>{i18n.t("jobFilterRecent")}</Text>
                </TouchableHighlight>
                <View style={{padding:10}}></View>
                <TouchableHighlight onPressOut={() =>{
                    setJobFilter("recommend");
                    filterData("recommend");
                }}>
                    <Text style={jobFilter === "recommend"?  styles.accountTitleActive : styles.accountTitle}>{i18n.t("jobFilterRecommend")}</Text>
                </TouchableHighlight>
                <View style={{padding:10}}></View>
                <TouchableHighlight onPressOut={() =>{
                    setJobFilter("loved");
                    filterData("loved");
                } }>
                    <Text style={jobFilter === "loved"?  styles.accountTitleActive : styles.accountTitle}>{i18n.t("jobFilterLoved")}</Text>
                </TouchableHighlight>

        
        
        
    </View>)
    
}

const styles = StyleSheet.create({
    segmentContainer:{
        flexDirection:"row",
        padding:5,
        marginLeft:50,
    },
    accountTitle:{
        fontSize: 20,
        textAlign: "center",
    },
    accountTitleActive:{
        fontSize: 20,
        fontWeight:"bold",
        textAlign: "center",
        textDecorationLine:"underline",
        textDecorationColor:"#800080",
    },
    
})