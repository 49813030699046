import React, {useState, useEffect} from "react";
import {View, Text, StyleSheet, TouchableOpacity, SafeAreaView, Platform, Image, StatusBar} from "react-native"
import isMobile from "../Modules/isMobile";
import {onAuthStateChanged, getAuth} from "firebase/auth";
import { Ionicons,MaterialCommunityIcons } from '@expo/vector-icons';
import i18n from 'i18n-js';
import {useRoute} from '@react-navigation/native';
import SearchBar from "./SearchBar";


export default function Header({navigation}) {
    const [signedIn, setIsSigned] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    
    useEffect(()=>{
        onAuthStateChanged(getAuth(),
          (authUser) => {
    
            if(authUser) {
                setIsSigned(true);
            } else {
                setIsSigned(true);
            }
    
          }
        );
      },[]);
    
    return(
    <>
        <SafeAreaView style={styles.header}>
            {isMobile() && Platform.OS != "web" && (useRoute().name === "JobPage")? 
                <TouchableOpacity style={styles.menuIconView} onPress={() =>{
                    navigation.navigate('Jobs');
                }}>
                    <Ionicons name="arrow-back-outline" size={40} color="gray" />
                </TouchableOpacity>
            : 
                <></>
            }
            {isMobile() && Platform.OS != "web" && (useRoute().name === "CompanyPage")? 
                <TouchableOpacity style={styles.menuIconView} onPress={() =>{
                    navigation.navigate('Companies');
                }}>
                    <Ionicons name="arrow-back-outline" size={40} color="gray" />
                </TouchableOpacity>
            : 
                <></>
            }
            {isMobile()  && (useRoute().name != "JobPage" && useRoute().name != "CompanyPage")? 
                <TouchableOpacity style={styles.menuIconView} onPress={() =>{
                        navigation.toggleDrawer();
                }}>
                    <Ionicons name="menu" size={40} color="gray" />
                </TouchableOpacity>
            : 
                <></>
            }
            <TouchableOpacity style={{flexDirection:"row", alignItems:"center"}} disabled={Platform.OS == "web"? false: true } onPress={() => {
                            navigation.navigate('LaunchPage');
                        } }>
                <Text style={styles.title}>{i18n.t('headerTitle')}</Text>
                <View style={styles.briefcaseView}>
                    <Image source={require('../images/baara.png')} style={{ width: 35, height: 35 }} />
                </View>
                
            </TouchableOpacity>
            {isMobile()? 
            undefined
            : 
                <>
                    <View style={styles.navBarItemView}>
                        <TouchableOpacity style={styles.navBarItem} onPress={() => {
                            navigation.navigate('Jobs');
                        } }>
                            <Text style={useRoute().name === "Jobs"? styles.navBarItemTextActive: styles.navBarItemText}>{i18n.t('navTitleJobs')}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.navBarItem} onPress={() => {
                            navigation.navigate('Companies');
                        } }>
                                <Text style={useRoute().name === "Companies"? styles.navBarItemTextActive: styles.navBarItemText}>{i18n.t('navTitleCompanies')}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.navBarItem} onPress={() => {
                            navigation.navigate('Certificates');
                        } }>
                                <Text style={useRoute().name === "Certificates"? styles.navBarItemTextActive: styles.navBarItemText}>{i18n.t('navTitleCertificates')}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.navBarItem} onPress={() => {
                            navigation.navigate('About');
                        } }>
                                <Text style={useRoute().name === "About"? styles.navBarItemTextActive: styles.navBarItemText}>{i18n.t('navTitleAbout')}</Text>
                        </TouchableOpacity>
                        
                        <View style={{flex:1, justifyContent:"flex-end"}}>
                            {signedIn? 
                                <TouchableOpacity style={[styles.navBarItem, {marginRight:20}]} onPress={() => {
                                    navigation.navigate('Account');
                                } }>
                                        <Text style={[useRoute().name === "Account"? styles.navBarItemTextActive : styles.navBarItemText, { textAlign:"right"}]}>{i18n.t('navTitleAccount')}</Text>
                                </TouchableOpacity> : 

                                <TouchableOpacity style={[styles.navBarItem, { marginRight:20}]} onPress={() => {
                                    navigation.navigate('Account');
                                } }>
                                        <Text style={[useRoute().name === "Account"? styles.navBarItemTextActive : styles.navBarItemText, { textAlign:"right"}]}>{i18n.t('navTitleSignUp')}</Text>
                                </TouchableOpacity> 
                            }
                        </View> 
                    </ View>
                    
                    
                    
                </>
            }
            { (useRoute().name === "Jobs" || useRoute().name === "Companies")?
                <TouchableOpacity style={styles.searchIconView} onPress={() =>{
                    setShowSearch(!showSearch);
                }}>
                    
                    <Ionicons size={30} style={{color:showSearch? "black":"lightgray", borderBottomWidth:showSearch? 2:undefined, borderBottomColor:"purple"}} name="search-outline" ></Ionicons>
                    
                </TouchableOpacity>
                :
                undefined
            }

        </SafeAreaView>

        {showSearch?
            
            <SearchBar placeholderType={useRoute().name} onSearch={(text, name) =>{
                navigation.navigate(name, {search:text});
            }} />
        :
            undefined
        }
    </>
    )
    
}

const styles = StyleSheet.create({
    header:{
        flexDirection: "row",
        alignItems:"center",
        paddingLeft: 10,
        backgroundColor:"white", 
        minHeight: 60,
        borderBottomWidth:1,
        borderBottomColor:"lightgray"
    },
    menuIconView:{
        width:40, 
        height:40,
        marginBottom:5,
    },
    title:{
        fontSize: 25,
        fontFamily:"ArialRoundedMTBold",
        fontWeight: "bold",
        color:"#800080"
    },
    navBarItemView:{
        flexDirection:"row",
        flex:1,
        marginLeft:30,
    },
    navBarItem:{
        marginLeft: 30,
        
    },
    navBarItemTextActive:{
        fontSize: 18,
        fontWeight: "bold",
        textDecorationLine:"underline",
        textDecorationColor:"#800080"
    },
    navBarItemText:{
        fontSize: 18,
        color:"gray"     
    },
    searchIconView:{
        marginLeft: "auto",
        marginRight: 20,
        
    },
    briefcaseIcon:{
        marginLeft:5,
        color:"#800080"
    },
    briefcaseView:{
        position: "absolute",
        left: 95
    },
    briefcaseText:{
        position: "absolute",
        left: 18,
        top:8,
        fontSize:28,
        fontWeight:"bold",
        color:"white"
    }
})