import React, {useEffect, useState} from "react";
import { Dimensions, Platform } from "react-native";

export default function isMobile (){
    const [isMobile, setMobile] = useState(Dimensions.get("window").width > 800 && Platform.OS == "web"? false: true);
    if(Platform.OS === "web"){
        useEffect(() => { 
        
            if(Dimensions.get("window").width > 800){
                setMobile(false);
            }else{
                setMobile(true);
            }
            const subscription = Dimensions.addEventListener(
                "change",
                ({ window, screen }) => {
                    if(window.width > 800){
                        setMobile(false);
                    }else{
                        setMobile(true);
                    }
                }
              );
              return () => subscription?.remove();
        });
    }
    
    return isMobile;
}