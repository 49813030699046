import {initializeApp} from "firebase/app"
import {collection, getFirestore, query, setDoc, getDocs, where, doc, onSnapshot, getDoc, startAt, orderBy, endAt, addDoc, updateDoc, arrayUnion, arrayRemove, deleteDoc} from "firebase/firestore";
import {onAuthStateChanged, getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, signOut, sendEmailVerification, updateProfile} from "firebase/auth"
import {getStorage, ref, uploadBytes, getDownloadURL, deleteObject} from "firebase/storage"
import { Platform } from "react-native";
import { getRegion, checkDataForTranslation } from "./GlobalFunctions";
const firebaseConfig = {
    apiKey: "AIzaSyCe7TjcRc3Ly1JQ64a-0Dr3UkyQWjCJVzI",
    authDomain: "jobs-5f9c0.firebaseapp.com",
    projectId: "jobs-5f9c0",
    storageBucket: "jobs-5f9c0.appspot.com",
    messagingSenderId: "311955858049",
    appId: "1:311955858049:web:e980d055de1870307ea380",
    measurementId: "G-L17ZXT19WC"
  };
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);


const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

function toTitleCase( str ) 
{
   return str.split(/\s+/).map( s => s.charAt( 0 ).toUpperCase() + s.substring(1).toLowerCase() ).join( " " );
}


export const getJobs = async (prop, key, search) => {
    
    var jobQ = query(collection(db, "jobs"), where("active", "==", true));
    let jobs =[];
    if((typeof search) === "string" || search == undefined || search == null){
        if(search && search.includes("where:")){
            var locationSplit1 = search.split("where:");
            if(locationSplit1[1]){
                var location = locationSplit1[1];
                if(location.includes(" ")){
                    var locationSplit2 = locationSplit1[1].split(" ");
                    location = locationSplit2[0];
                }
                jobQ = query(jobQ , where("location", '>=', location.toLowerCase()), where("location", '<=', location.toLowerCase()+ '\uf8ff'));
                search = search.replace('where:'+location,'');
            }
        }
        if(search && search != ""){
            jobQ = query(jobQ , where("tags", 'array-contains-any', search.toLowerCase().split(" ")));
        }
        const querySnapshot = await getDocs(jobQ);
    
        querySnapshot.forEach((doc) => {
            if(checkDataForTranslation(doc.data())){
                jobs = [...jobs, {id: doc.id, ...checkDataForTranslation(doc.data())} ]
            }
        });
        if(key){
            prop.setState({[key]: jobs});
        }else{
            prop(jobs);
        }
    }else if((typeof search) === "object"){
        search.forEach(async data => {
            var id = data.id? data.id: data;
            
            const result = await getDoc(doc(db, "jobs", id));

            if(result.data()){
                if(checkDataForTranslation(result.data())){
                    jobs.push({...{id: result.id, ...result.data()}, ...data});
                }   
            }
            if(search[search.length - 1] === data){
                if(key){
                    prop.setState({[key]: jobs, jobId:null});
                }else{
                    prop(jobs);
                }
            }
        });
    }
    
    
    
    
}
export const getJob = async (prop, key, id, disableTrans) => {
    
    const result = await getDoc(doc(db, "jobs", id));
    
    if(checkDataForTranslation(result.data()) && (disableTrans == null || disableTrans == false)){
        if(key){
            prop.setState({[key]: checkDataForTranslation(result.data())});
        }else{
            prop(checkDataForTranslation(result.data()));
        }
    }else if(disableTrans){
        if(key){
            prop.setState({[key]: result.data()});
        }else{
            prop(result.data());
        }
    }
    
}
export const getCompany = async (prop, key, id) => {
    
    const result = await getDoc(doc(db, "companies", id));
    if(checkDataForTranslation(result.data())){
        if(key){
            prop.setState({[key]: checkDataForTranslation(result.data())});
        }else{
            prop(checkDataForTranslation(result.data()));
        } 
    }
    
}
export const getCompanyJobs = async (prop, key, companyId, shouldGetAll) => {
    
    var jobQ = query(collection(db, "jobs"), where("active", "==", true));
    if(shouldGetAll){
        jobQ = collection(db, "jobs")
    }
    jobQ = query(jobQ , where("companyId", '==', companyId));
    
    const querySnapshot = await getDocs(jobQ);
    let jobs =[];
    querySnapshot.forEach((doc) => {
        if(checkDataForTranslation(doc.data())){
            jobs = [...jobs, {id: doc.id, ...doc.data()} ];
        }
    });
    if(key){
        prop.setState({[key]: jobs});
    }else{
        prop(jobs);
    }
    
}
export const getCompanies = async (prop, key, search) => {
    var compQ = query(collection(db, "companies"), where("active", "==", true));
    if(search){
        
        compQ = query(compQ , where("tags", 'array-contains-any', search.toLowerCase().split(" ")));
    }
    const querySnapshot = await getDocs(compQ);
    let companies =[];
    querySnapshot.forEach((doc) => {
        if(checkDataForTranslation(doc.data())){
            companies = [...companies, {id: doc.id, ...checkDataForTranslation(doc.data())} ];
        }
    });
    if(key){
        prop.setState({[key]: companies});
    }else{
        prop(companies);
    }
    
}
export const getUserData =async (prop, key) => {
    if(getAuth().currentUser){
        
        onSnapshot(doc(db, "users", getAuth().currentUser?.uid), (doc) => {
            var data= doc.data() != null? doc.data(): {};
            
            if(key){
                prop.setState({[key]: data})
            }else{
                prop(data);
            }
            
        });
        
    }
    
}
export const setUserData =async (data, callback) => {
    if(auth.currentUser){
        var userRef = doc(db, "users", auth.currentUser.uid);
        setDoc(userRef, data, { merge: true }).then(() => {
            callback({ isError:false, dataSet:true });
        }).catch((error) => {
            callback({ isError:true, dataSet:false, error:error.message });
        });
        
    }else{
        callback({ isError:true, error:"Not Signed In" });
    }
}


export const isSignedIn = (prop, key) => {
    function setState(value) {
        prop(value);
    }
    if(auth.currentUser){
        setState(true);
    }else{
        setState(false);
    }
    onAuthStateChanged(auth, (user) => {
        if (user) {
            setState(true);
        } else {
            setState(false);
        }
    }
  );
};
export const signUp = (email, pass, callback) => {
    createUserWithEmailAndPassword(auth, email, pass).then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        callback({user:user, isError:false});
    
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        callback({user:null, isError:true, error:errorMessage });
    });
};
export const signIn = (email, pass, callback) => {
    
    signInWithEmailAndPassword(auth, email, pass).then((userCredential) => {
        const user = userCredential.user;
        callback({user:user, isError:false});
    
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        callback({user:null, isError:true, error:errorMessage });
    });
};
export const forgotPassword = (email, callback) => {
    sendPasswordResetEmail(auth, email).then(()=>{
        callback({isError:false});
    }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        callback({user:null, isError:true, error:errorMessage });
    });
};
export const logOut = (callback) => {
    signOut(auth).then(()=>{
        callback({isError:false});
    }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        callback({user:null, isError:true, error:errorMessage });
    });
};
export const setDisplayName = (name,callback) => {
    if(auth.currentUser){
        updateProfile(auth.currentUser, {
            displayName: name
          }).then(() => {
            callback({isError:false});
          }).catch((error) => {
            callback({isError:true, error:error.message});
          });
    }
    
};
export const myUser = () => {
    return auth.currentUser;
};

export const sendVerification = (callback) => {
    sendEmailVerification(auth.currentUser).then(()=>{
        callback({isError:false});
    }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        callback({user:null, isError:true, error:errorMessage });
    });
};

export const uploadUserFile = (file, callback, name) =>{
    if(auth.currentUser){
        const storageRef = ref(storage, "users/"+auth.currentUser.uid+"/"+name);
        uploadBytes(storageRef, file).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                callback({ isError:false, url:downloadURL });
            }).catch((error) =>{
                callback({ isError:true, error:errorMessage });
            });
        }).catch((error) => {
            callback({ isError:true, error:error.message });
        });
    }else{
        callback({ isError:true, error:"Not Signed In" });
    }
}
export const getUserFile = (name, callback) =>{
    if(auth.currentUser){
        const storageRef = ref(storage, "users/"+auth.currentUser.uid+"/"+name)
        getDownloadURL(storageRef).then((downloadURL) => {
            callback({ isError:false, url:downloadURL });
        }).catch((error) =>{
            callback({ isError:true, error:error.message });
        });
    }else{
        callback({ isError:true, error:"Not Signed In" });
    }
}
export const deleteUserFile = (name, callback) =>{
    if(auth.currentUser){
        const storageRef = ref(storage, "users/"+auth.currentUser.uid+"/"+name);
        deleteObject(storageRef).then(() => {
            callback({ isError:false });
        }).catch((error) => {
            callback({ isError:true, error:error.message });
        });
        
    }else{
        callback({ isError:true, error:"Not Signed In" });
    }
}

export const applyToJob = (jobId, data, callback) =>{
    if(auth.currentUser){
        var jobRef = doc(db, "jobs/"+jobId+"/applications", auth.currentUser.uid);
        setDoc(jobRef, data, { merge: true }).then(() => {
            callback({ isError:false, jobSubmit: true });
        }).catch((error) => {
            callback({ isError:true, jobSubmit: false, error:error.message });
        });
        var d = new Date();
        setUserData({jobsApplied:[{id:jobId, date:d}]}, callback);
        
    }else{
        callback({ isError:true, error:"Not Signed In" });
    }
}

export const uploadJobFile = (file, jobId, callback, name) =>{
    if(auth.currentUser){
        const storageRef = ref(storage, "jobs/"+jobId+"/"+name);
        uploadBytes(storageRef, file).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                callback({ isError:false, url:downloadURL, name:name });
            }).catch((error) =>{
                callback({ isError:true, error:error.message, name:name });
            });
        }).catch((error) => {
            callback({ isError:true, error:error.message, name:name });
        });
    }else{
        callback({ isError:true, error:"Not Signed In", name:name });
    }
}

export const addReviewToCompany = (reviewText, starCount, companyId, companyName, callback) =>{
    if(auth.currentUser){
        if(auth.currentUser.displayName == null || auth.currentUser.displayName == ""){
            callback({ isError:true, error:"No Display Name (set in your Account)" });
            return;
        }
        
        var reviewRef = doc(db, "companies", companyId);
        var d = new Date();
        var review = {
            userId: auth.currentUser.uid,
            user: auth.currentUser.displayName,
            review: reviewText,
            stars: starCount,
            date: d, 
            companyId: companyId,
            companyName: companyName, 
        }
        var data = {
            reviews:arrayUnion(review)
        };
    
        updateDoc(reviewRef, data).then(() => {
            callback({ isError:false, dataSet:true, review:review });
        }).catch((error) => {
            callback({ isError:true, dataSet:false, error:error.message });
        });
        setUserData({reviews:arrayUnion(review)}, callback);
        
    }else{
        callback({ isError:true, error:"Not Signed In" });
    }
}
export const updateReviewToCompany = (review, stars, reviewData, companyId, companyName, callback) =>{
    if(auth.currentUser){
        if(auth.currentUser.displayName == null || auth.currentUser.displayName == ""){
            callback({ isError:true, error:"No Display Name (set in your Account)" });
            return;
        }
        
        var reviewRef = doc(db, "companies", companyId);
        var data = {
            reviews:arrayRemove(reviewData)
        };
    
        updateDoc(reviewRef, data).then(() => {
            var d = new Date();
            var reviewObj = {
                userId: auth.currentUser.uid,
                user: auth.currentUser.displayName,
                review: review,
                stars: stars,
                date: d, 
                companyId: companyId,
                companyName: companyName, 
            }
            var data2 = {
                reviews:arrayUnion(reviewObj)
            };
    
        
            updateDoc(reviewRef, data2).then(() => {
                callback({ isError:false, dataSet:true, review:reviewObj, oldReview:reviewData });
            }).catch((error) => {
                callback({ isError:true, dataSet:false, error:error.message });
            });
            setUserData({reviews:arrayUnion(review)}, callback);
        }).catch((error) => {
            callback({ isError:true, dataSet:false, error:error.message });
        });
        setUserData({reviews:arrayRemove(reviewData)}, callback);
        
    }else{
        callback({ isError:true, error:"Not Signed In" });
    }
}
export const removeReviewFromCompany = (review, companyId, callback) =>{
    if(auth.currentUser){
        if(auth.currentUser.displayName == null || auth.currentUser.displayName == ""){
            callback({ isError:true, error:"No Display Name (set in your Account)" });
            return;
        }
        
        var reviewRef = doc(db, "companies", companyId);
        var data = {
            reviews:arrayRemove(review)
        };
    
        updateDoc(reviewRef, data).then(() => {
            callback({ isError:false, dataSet:true, review:review });
        }).catch((error) => {
            callback({ isError:true, dataSet:false, error:error.message });
        });
        setUserData({reviews:arrayRemove(review)}, callback);
        
    }else{
        callback({ isError:true, error:"Not Signed In" });
    }
}

export const createNewJob = (jobData, companyId,callback) =>{
    if(auth.currentUser){
        
        var jobRef = collection(db, "jobs");
        var d = new Date();
        jobData.postedDate = d;
        jobData.companyId = companyId;
        jobData.jobType = "full";
        if(jobData.language == null){
            jobData.language = {};
        }
        jobData.jobType = "full";
        jobData.applyData = [{required:true, type:"resume"}, {required:false, type:"coverLetter"}];
    
        addDoc(jobRef, jobData).then((docRef) => {
            callback({ isError:false, dataSet:true });
        }).catch((error) => {
            callback({ isError:true, dataSet:false, error:error.message });
        });
        
        
    }else{
        callback({ isError:true, error:"Not Signed In" });
    }
}

export const updateJob = (jobData, jobId, callback) =>{
    if(auth.currentUser){
        
        var jobRef = doc(db, "jobs", jobId);
    
        updateDoc(jobRef, jobData).then(() => {
            callback({ isError:false, dataSet:true });
        }).catch((error) => {
            callback({ isError:true, dataSet:false, error:error.message });
        });
        
        
    }else{
        callback({ isError:true, error:"Not Signed In" });
    }
}
export const deleteJob = (jobId, callback) =>{
    if(auth.currentUser){
        var jobRef = doc(db, "jobs", jobId);
    
        deleteDoc(jobRef).then(() => {
            callback({ isError:false, dataSet:true });
        }).catch((error) => {
            callback({ isError:true, dataSet:false });
        });
        
    }else{
        callback({ isError:true, error:"Not Signed In" });
    }
}

export const updateCompany = (companyData, companyId, callback) =>{
    if(auth.currentUser){
        
        var companyRef = doc(db, "companies", companyId);
    
        updateDoc(companyRef, companyData).then(() => {
            callback({ isError:false, dataSet:true });
        }).catch((error) => {
            callback({ isError:true, dataSet:false, error:error.message });
        });
        
        
    }else{
        callback({ isError:true, error:"Not Signed In" });
    }
}

export const getJobApplications = async (jobId, callback) => {
    var compQ =collection(db, "jobs/"+jobId+"/applications")
    const querySnapshot = await getDocs(compQ);
    
    var companies =[];
    querySnapshot.forEach((doc, index, array) => {
        companies.push(doc.data());
    });
    callback(companies);
    
    
    
}


export const uploadCompanyFile = (id,file, callback, name) =>{
    if(auth.currentUser){
        const storageRef = ref(storage, "users/"+auth.currentUser.uid+"/"+name);
        uploadBytes(storageRef, file).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                callback({ isError:false, url:downloadURL });
            }).catch((error) =>{
                callback({ isError:true, error:errorMessage });
            });
        }).catch((error) => {
            callback({ isError:true, error:error.message });
        });
    }else{
        callback({ isError:true, error:"Not Signed In" });
    }
}



export const getCerts = async (prop, key) => {
    
    var certsQ = query(collection(db, "certificates"), where("active", "==", true));
    let certs =[];
    const querySnapshot = await getDocs(certsQ);
    querySnapshot.forEach((doc) => {
        if(checkDataForTranslation(doc.data())){
            certs = [...certs, {id: doc.id, ...(doc.data())} ]
        }
        
    });

    
    if(key){
        prop.setState({[key]: certs});
    }else{
        prop(certs);
    }
}
    

export const addSavedJob = async (jobId, callback) => {
    if(auth.currentUser){
        var userRef = doc(db, "users", auth.currentUser.uid);
        setDoc(userRef, {saveForLater:arrayUnion(jobId)}, { merge: true }).then(() => {
            callback({ isError:false, dataSet:true });
        }).catch((error) => {
            callback({ isError:true, dataSet:false, error:error.message });
        });
        
        
    }else{
        callback({ isError:true, error:"Not Signed In" });
    }
}   
export const removeSavedJob = async (jobId, callback) => {
    if(auth.currentUser){
        var userRef = doc(db, "users", auth.currentUser.uid);
        setDoc(userRef, {saveForLater:arrayRemove(jobId)}, { merge: true }).then(() => {
            callback({ isError:false, dataSet:true });
        }).catch((error) => {
            callback({ isError:true, dataSet:false, error:error.message });
        });
        
    }else{
        callback({ isError:true, error:"Not Signed In" });
    }
}   

export const getJobsLike = async (prop, key, jobId, shouldInclude) => {
    if(jobId){
        const result = await getDoc(doc(db, "jobs", jobId));
        var jobQ = query(collection(db, "jobs"), where("active", "==", true));
        let jobs =[];
        if(result && result.data() && result.data().tags){
            jobQ = query(jobQ , where("tags", 'array-contains-any', result.data().tags));
            const querySnapshot = await getDocs(jobQ);
            querySnapshot.forEach((doc) => {
                if(checkDataForTranslation(doc.data())){
                    if(shouldInclude){
                        jobs = [...jobs, {id: doc.id, ...checkDataForTranslation(doc.data())} ];
                    }else{
                        if(jobId != doc.id){
                            jobs = [...jobs, {id: doc.id, ...checkDataForTranslation(doc.data())} ];
                        }
                    }
                }
            });
        }
        if(jobs.length != 0){
            if(key){
                prop.setState({[key]: jobs});
            }else{
                prop(jobs);
            }
        }
        
    }
}