import React, {useState} from "react";
import { View, TextInput, SafeAreaView, Text, StyleSheet, ScrollView, Platform, Image, Linking} from "react-native";
import CustomButton from "../Components/CustomButton";
import {contactBara} from "../Modules/GlobalFunctions";
import i18n from "i18n-js";
import Footer from "../Components/Footer";
export default class Hire extends React.Component{
    constructor(props){
        super(props);
        
    }
    render(){
        
        return(
            <SafeAreaView style={{flex:1}}>
                <ScrollView style={{flex:1}} contentContainerStyle={{flexGrow: 1, justifyContent: 'space-between'}}>
                    
                    <View style={styles.container}>
                        <Text style={styles.title}>{i18n.t("hireTitle")}</Text>
                        <Image
                            style={styles.bannerImage}
                            source={require('../images/hire/cover.jpg')}
                        /> 
                        <Text style={styles.subtitle}>{i18n.t("hireSubtitle")}</Text>
                        <View style={{marginTop:40}} />
                        <CustomButton text={i18n.t("hireButton")} onPress={()=>{
                            Linking.openURL("https://forms.gle/SHbDBGR96BWvXvrRA");
                        }} />
                    </View>
                    
                    {Platform.OS == "web"? 
                    <Footer navigation={this.props.navigation}/>
                    : undefined}
                </ScrollView>
                
            </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({
    container:{
        flex:1,
        flexGrow: 1,
        justifyContent:"center",
        alignContent:"center",
        alignItems: 'center',
    },
    title:{
        fontWeight:"bold",
        fontSize: 35,
        textAlign:"center",
        marginTop: 10,
    },
    subtitle:{
        fontWeight:"bold",
        fontSize: 20,
        textAlign:"center",
        marginTop: 20,
    },
    bannerImage:{
        marginTop:20,
        width: 500,
        height: 300,
        justifyContent: 'center',
        alignItems: 'center'
    }

    
})