import React, {useState} from "react";
import { View, FlatList, SafeAreaView, Text, StyleSheet, Platform, TouchableOpacity, Image, Pressable, Linking } from "react-native";
import {getCompany, getCompanyJobs} from "../Modules/Firebase";
import Reviews from "../Components/Reviews";
import CustomButton from "../Components/CustomButton";
import AddReviewView from "../Components/AddReviewView"
import { ScrollView } from "react-native-gesture-handler";
import Footer from "../Components/Footer";
import ShowToast from '../Modules/ShowToast';
import {getAuth} from "firebase/auth";
import i18n from "i18n-js";
import {containsUserId, containsOwnerId} from "../Modules/GlobalFunctions";
import CompanyJobList from "../Components/CompanyJobList";
import { Ionicons } from '@expo/vector-icons';

//Add Contact info email/address
export default class CompanyPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data: null,
            jobData: null,
            showReviewModal: false,
            reviewData: null,
            toastMessage: "",
            companyId: (props.route && props.route.params && props.route.params.id)?  props.route.params.id : props.companyId,
        }
    }
    updateCompany(){
        if(this.state.companyId != null){
            getCompany(this, "data", this.state.companyId);
            getCompanyJobs(this, "jobData", this.state.companyId);
        }
    }
    componentDidUpdate(){
        if(this.props.route?.params?.id && this.props.route.params.companyId != this.state.id){
            this.setState({companyId: this.props.route.params.id})
            this.updateCompany();
        }
        if(this.props.companyId && this.props.companyId != this.state.companyId){
            this.setState({companyId: this.props.companyId, data: null})
            this.updateCompany();
        }
        
    }
    render(){
        if(this.state.data == null){
            this.updateCompany();
        }
        return(
            <SafeAreaView style={{flex:1}}>
                {/* External Window for Split View */}
                {this.props.splitView && this.props.companyId?
                    <View style={{alignSelf:"flex-end"}}>
                    <TouchableOpacity onPress={()=>this.props.navigation.navigate('CompanyPage', {id:this.props.companyId})}>
                        <Ionicons name="open-outline" size={30} />
                    </TouchableOpacity>
                    </View>
                    : 
                    undefined
                }
                {/* Toast Display */}
                <ShowToast message={this.state.toastMessage} didShow={()=>this.setState({toastMessage:""})}/>
                {/* Review Pop up */}
                <AddReviewView data={this.state.data} id={this.state.companyId} visible={this.state.showReviewModal} hideModal={(review, oldReview)=>{
                        this.setState({showReviewModal:false, reviewData:null});
                        if(review && review.stars && this.state.data){
                            var tempData = this.state.data;
                            tempData.reviews = [...tempData.reviews, review];
                            this.setState({data:tempData});
                        }
                        if(oldReview && oldReview.stars){
                            var tempData = this.state.data;
                            const index = tempData.reviews.indexOf(oldReview);
                            if (index > -1) {
                                tempData.reviews.splice(index, 1); // 2nd parameter means remove one item only
                            }
                            this.setState({data:tempData});
                        }
                    }} navigation={this.props.navigation} setToast={(message:String)=>this.setState({toastMessage:message})} reviewData={this.state.reviewData} />
                <ScrollView>
                    {this.state.data && this.state.data.active?
                        <View style={styles.container}>
                            <View style={{flexDirection:"row", alignContent:"center"}}>
                                {this.state.data.icon? 
                                <Image
                                style={styles.companyLogo}
                                source={{
                                uri: this.state.data.icon,
                                }}
                                /> : undefined }
                                <View style={{flex:1}}>
                                    <Text style={styles.title}>{this.state.data.name}</Text>
                                    <Text style={styles.description}>{this.state.data.about}</Text>
                                </View>
                            </View>
                            <View style={{borderTopWidth: 3, marginTop: 50, width:"90%"}}></View>
                            {this.state.data.email || this.state.data.website?
                                <Text style={styles.jobTitle}>Contact:</Text>
                            :
                            undefined
                            }
                            
                            {this.state.data.email?
                                <TouchableOpacity onPress={()=> Linking.openURL("mailto:"+this.state.data.email)}>
                                    <Text style={styles.link}>{this.state.data.email}</Text>   
                                </TouchableOpacity>
                                 :undefined
                            }
                            {this.state.data.website?
                                 <TouchableOpacity onPress={()=> Linking.openURL(this.state.data.website)}>
                                 <Text style={styles.link}>{this.state.data.website}</Text>   
                             </TouchableOpacity>
                              :undefined
                            }
                            <View style={{borderTopWidth: 3, marginTop: 50, width:"90%"}}></View>
                            <Text style={styles.jobTitle}>{i18n.t("companyPageJobsText")}</Text>
                            <CompanyJobList jobs={this.state.jobData}  navigation={this.props.navigation} />
                            <View style={{borderTopWidth: 3, marginTop: 50, width:"90%"}}></View>
                            <Text style={styles.reviewTitle}>{i18n.t("companyPageReviewsText")}</Text>
                            <Reviews updateReviews={(event)=>{
                                if(event.review && this.state.data.reviews){
                                    var tempData = this.state.data;
                                    tempData.reviews = tempData.reviews.filter(function(item) {
                                        return item !== event.review
                                    })
                                    this.setState({data:tempData});
                                }
                            }} reviews={this.state.data.reviews} id={this.state.companyId} updateReview={(review)=> this.setState({showReviewModal:true, reviewData:review})} />
                            <CustomButton text={i18n.t("companyPageAddReviewText")} onPress={() =>{
                                if(this.state.data != null){
                                    if(this.state.data.reviews && getAuth().currentUser && containsUserId(this.state.data.reviews, getAuth().currentUser?.uid)){
                                        this.setState({toastMessage:i18n.t("companyPageAlreadyReviewedAlert")})
                                    }else if(this.state.data && this.state.data.owners && getAuth().currentUser && containsOwnerId(this.state.data.owners, getAuth().currentUser?.uid)){
                                        this.setState({toastMessage:i18n.t("companyPageAlreadyOwnerAlert")})
                                    }else{
                                        this.setState({showReviewModal:true});
                                    }
                                    
                                }
                                
                            }} />
                        </View>

                        :
                        <View style={styles.container}>
                            <Text>{this.props.splitView != true?i18n.t("loading"):undefined}</Text>
                        </View>

                    }
                    {Platform.OS == "web" && this.props.splitView != true? 
                        <Footer navigation={this.props.navigation}/>
                    : undefined}
                </ScrollView>
                
            </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({

    container:{
        flex:1,
        alignItems:"center",
        backgroundColor:"white",
    },
    title:{
        paddingTop: 30,
        fontSize: 30,
        textAlign: "center",
    },
    jobTitle:{
        fontSize: 20,
        marginBottom: 10,
        textAlign: "center",
    },
    reviewTitle:{
        fontSize: 20,
        marginBottom: 10,
        textAlign: "center",
    },
    description:{
        paddingTop: 20,
        fontSize: 15,
        paddingHorizontal: 10,
        textAlign: "center",
    },
    companyLogo:{
        height: 100,
        width: 100,
        marginRight:10,
        marginTop:20,
    },
    link:{
        paddingTop: 10,
        fontSize: 15,
        paddingHorizontal: 10,
        textDecorationLine:"underline",
        color:"blue",
        textAlign: "center",
    },
})