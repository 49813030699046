import React, {useState} from "react";
import { View, Text, StyleSheet , TouchableOpacity} from "react-native";

export default function CustomButton({text, onPress, fontSize}) {
    return (
        <TouchableOpacity style={styles.defaultButtonView} onPress={onPress? onPress: undefined}>
            <Text style={[styles.defaultButtonText, fontSize?{fontSize:fontSize}:undefined]}>{text}</Text>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    defaultButtonView:{
        borderRadius: 15,
        backgroundColor: "#800080",
        alignSelf:"center",
        padding: 10,
        marginBottom: 10,
    },
    defaultButtonText:{
        fontSize: 20,
    },
});