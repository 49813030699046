import React, {useState, useRef, createRef} from "react";
import {TextInput, View, Text, StyleSheet, TouchableOpacity, Modal, Pressable, ScrollView, SafeAreaView, TouchableWithoutFeedback, TouchableHighlight} from "react-native"
import isMobile from "../Modules/isMobile";
import { Ionicons } from '@expo/vector-icons';
import i18n from 'i18n-js';
import CheckBox from 'react-native-checkbox-lite';
import {getJob, updateJob, createNewJob,getJobApplications} from "../Modules/Firebase"
import CustomButton from "./CustomButton";
import {onAuthStateChanged, getAuth} from "firebase/auth";
import StarPicker from "./StarPicker";
import {exportToCsvFile} from "../Modules/GlobalFunctions"
export default function AddJob({visible, hideModal, id, setToast, companyId}) {
    const [jobData, setJobData] = useState();
    const [language, setLanguage] = useState("en");
    var newJob = id == "new";
    if(id === "new" && jobData == null){
        setJobData({});
    }else if(jobData == null && id){
        getJob(setJobData, null, id, true);
    }else if(jobData != null && id ==null){
        setJobData(null)
    }
    
    function getApplications(data){
        if(data === undefined || data.length == 0){
            setToast(i18n.t("addJobNoAppData"));
        }else{
            exportToCsvFile(data);
        }
        
      }
    function addedCallback(event){
        if(event.isError){
          setToast(event.error);
        }else if(event.dataSet){
          setToast(i18n.t("addJobAddedJob"));
          hideModal(true);
        }
    }
    function updateCallback(event){
      if(event.isError){
        setToast(event.error);
      }else if(event.dataSet){
        setToast(i18n.t("addJobUpdatedJob"));
        hideModal(true);
      }
    }
    function getLanguageText(type){
        if(language==="en"){
            return jobData[type];
        }else if(jobData.language && jobData.language[language] && jobData.language[language][type]){
            return jobData.language[language][type];
        }
        return "";
    }
    function setLanguageText(type,text){
        if(language==="en"){
            setJobData({...jobData, [type]: text});
        }else{
            if(jobData.language[language]){
                setJobData({...jobData, language:{...jobData.language, [language]:{ ...jobData.language[language], [type]:text, companyId:companyId } }});
            }else{
                setJobData({...jobData, language:{...jobData.language, [language]:{ [type]:text } }});
            }
            
        }
    }
    return(
        <Modal animationType="slide"
            transparent={true}
            visible={visible}>
            <TouchableOpacity style={styles.modalBackground} onPress={hideModal}>
                <TouchableWithoutFeedback>
                    <View style={styles.modalView}>
                        <ScrollView contentContainerStyle={{justifyContent:"flex-start", alignContent:"center", flex:1}}>
                            {jobData? 
                                <>
                                    
                                    <Text style={styles.jobTitle}>{newJob? i18n.t("addJobAddJob") :i18n.t("addJobEditJob")}</Text>
                                    
                                    <View style={{marginTop:10}} />

                                    {newJob?
                                    undefined:<CustomButton text={i18n.t("addJobGetApplicationsButton")} onPress={()=>{
                                        getJobApplications(id, getApplications);
                                    } 
                                    } /> }
                                    <View style={{marginTop:10}} />
                                    <View style={{flex: 1, alignItems:"center", justifyContent:"center", marginVertical:20}}>
                                        <CheckBox text={i18n.t("addJobEditJobActive")} isChecked={jobData.active} onPress={()=>{
                                            setJobData({...jobData, active: !jobData.active});
                                        }} />
                                    </View>
                                    <View style={{marginTop:10}} />
                                    <View style={styles.segmentContainer}>
                                        <TouchableHighlight onPressOut={() => setLanguage("en") }>
                                            <Text style={language === "en"?  styles.accountTitleActive : styles.accountTitle}>{i18n.t("addJobEnglishTitle")}</Text>
                                        </TouchableHighlight>
                                        <View style={{padding:5}}></View>
                                        <TouchableHighlight onPressOut={() => setLanguage("fr")}>
                                            <Text style={language === "fr"?  styles.accountTitleActive : styles.accountTitle}>{i18n.t("addJobFrenchTitle")}</Text>
                                        </TouchableHighlight>
                                    </View>
                                    
                                   
                                    <Text style={styles.subTitle}>{i18n.t("addJobEditTitle")}</Text>
                                    <TextInput value={getLanguageText("title")} onChangeText={(text)=>{
                                        setLanguageText("title", text);
                                    }} style={styles.singleTextBox} />
                                    <Text style={styles.subTitle}>{i18n.t("addJobEditLocation")}</Text>
                                    <TextInput value={getLanguageText("location")}onChangeText={(text)=>{
                                        setLanguageText("location", text);
                                    }} style={styles.singleTextBox} />
                                    <Text style={styles.subTitle}>{i18n.t("addJobEditSalary")}</Text>
                                    <TextInput value={getLanguageText("salary")} onChangeText={(text)=>{
                                        setLanguageText("salary", text);
                                    }} style={styles.singleTextBox} />
                                    <Text style={styles.subTitle}>{i18n.t("addJobEditLongDescription")}</Text>
                                    <TextInput value={getLanguageText("longDescription")} onChangeText={(text)=>{
                                        setLanguageText("longDescription", text);
                                    }} style={styles.multiTextBox} multiline={true} />
                                    <Text style={styles.subTitle}>{i18n.t("addJobEditSearchTags")}</Text>
                                    <TextInput value={getLanguageText("tags")?getLanguageText("tags").join("\n"):""} onChangeText={(text)=>{
                                        setLanguageText("tags", text.split("\n"));
                                    }} style={styles.multiTextBox} multiline={true} />
                                    <View style={{marginTop:10}} />
                                    <Text style={styles.subTitle}>{i18n.t("addJobEditBenefits")}</Text>
                                    <TextInput value={getLanguageText("benefits")? getLanguageText("benefits").join("\n"):""} onChangeText={(text)=>{
                                        setLanguageText("benefits", text.split("\n"));
                                    }} style={styles.multiTextBox} multiline={true} />

                                    <Text style={styles.subTitle}>{i18n.t("addJobEditJobType")}</Text>
                                    <View style={{flex: 1, alignItems:"center", justifyContent:"center", marginVertical:20, flexDirection:"row"}}>
                                        <CheckBox style={{marginRight:10}} text={i18n.t("addJobEditJobTypeFull")} isChecked={jobData.jobType === "full"} onPress={()=>{
                                            setJobData({...jobData, jobType: "full"});
                                        }} />
                                        <CheckBox text={i18n.t("addJobEditJobTypePart")} isChecked={jobData.jobType === "part"} onPress={()=>{
                                            setJobData({...jobData, jobType: "part"});
                                        }} />
                                    </View>
                                    <View style={{marginTop:30}} />
                                    {newJob?
                                    <CustomButton text={i18n.t("addJobAddButton")} onPress={()=>{
                                        createNewJob(jobData, companyId,addedCallback);
                                         
                                     } 
                                     } /> : <CustomButton text={i18n.t("addJobUpdateButton")} onPress={()=>{
                                       updateJob(jobData, id, updateCallback);
                                        
                                    } 
                                    } /> }
                                </>
                                
                            :
                            <View style={styles.modalView}>
                                <Text style={{marginBottom: 10}}>Loading....</Text>
                            </View>}
                        </ScrollView>
                    </View>
                </TouchableWithoutFeedback>
              
            </TouchableOpacity>
        </Modal>
    )
    
}

const styles = StyleSheet.create({
    modalBackground:{
      flex:1,
      backgroundColor:"rgba(0, 0, 0, 0.42)",
      height: "100%",
      width: "100%",
      alignItems: 'center',
        justifyContent: 'center',
        
    },  
    modalView:{
        opacity: 1,
        borderRadius: 20,
        alignSelf:"center",
        justifyContent: 'center',
        padding: 10,
        height: "70%",
        width: "90%",
        textAlign:"center",
        backgroundColor:"lightgray"
    },
    jobTitle:{
        fontSize: 30,
        fontWeight: "bold",
        marginTop: 30,
    },
    subTitle:{
        fontSize: 20,
        marginTop: 10,
    },
    singleTextBox:{
        maxWidth: 400,
        minWidth: 300,
        alignSelf: "center",
        marginVertical: 15,
        minHeight: 30,
        backgroundColor: "white",
        borderRadius: 10,
        padding: 5,
    },
    multiTextBox:{
        maxWidth: 400,
        minWidth: 300,
        alignSelf: "center",
        marginVertical: 15,
        minHeight: 100,
        backgroundColor: "white",
        borderRadius: 10,
        padding: 5,
    },
    segmentContainer:{
        flexDirection:"row",
        alignSelf:"center",
        backgroundColor:"#ff4dff",
        padding:3,
        borderRadius: 10,
    },
    
    accountTitle:{
        fontSize: 20,
        padding:5,
        borderRadius: 10,
        fontWeight:"bold",
        textAlign: "center",
    },
    accountTitleActive:{
        fontSize: 20,
        fontWeight:"bold",
        textAlign: "center",
        backgroundColor:"#800080",
        padding:5,
        borderRadius: 10,
    },
});