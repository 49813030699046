import React, {useState} from "react";
import { View, StyleSheet , TouchableOpacity} from "react-native";
import { Ionicons } from '@expo/vector-icons';
export default function StarPicker({stars, onStarsEdit}) {
    function updateStars(star){
        onStarsEdit(star)
    }
    return(
        <View style={styles.starView}>
            <TouchableOpacity onPress={()=>updateStars(1)}>
                <Ionicons size={40} name={stars >= 1? "star":"star-outline"} />
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>updateStars(2)}>
                <Ionicons size={40} name={stars >= 2? "star":"star-outline"} />
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>updateStars(3)}>
                <Ionicons size={40} name={stars >= 3? "star":"star-outline"} />
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>updateStars(4)}>
                <Ionicons size={40} name={stars >= 4? "star":"star-outline"} />
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>updateStars(5)}>
                <Ionicons size={40} name={stars >= 5? "star":"star-outline"} />
            </TouchableOpacity>
        </View>

    )
   
}

const styles = StyleSheet.create({
    
    starView:{
        paddingTop: 30,
        flexDirection: "row",
        justifyContent: "center"
    },
    
});