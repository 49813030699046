import React, {useState} from "react";
import * as Localization from 'expo-localization';
import { View, TextInput, SafeAreaView, Text, StyleSheet, ScrollView, Platform, FlatList,Dimensions } from "react-native";
import CustomButton from "../Components/CustomButton";
import i18n from "i18n-js";
import Footer from "../Components/Footer";
import CertificateView from "../Components/CertificateView";
import {getCerts} from "../Modules/Firebase"
export default class Certificates extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            certificates:[{name:"Loading...", id:"3210"}],
            search: (props.route.params && props.route.params.search)?  props.route.params.search : null,
            isMobile: false,
        }
    }
    componentDidMount(){
        if(this.state.isMobile == null){
            if(Dimensions.get("window").width > 700){ 
                this.setState({isMobile:false});
            }else{
                this.setState({isMobile:true});
            }
        }
        Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                if(window.width >700){
                    this.setState({isMobile:false});
                    
                }else{
                    this.setState({isMobile:true});
                }
                
            }
          );
    }
    render(){

        if(this.state.certificates[0] && this.state.certificates[0].name  === "Loading..."){
            getCerts(this, "certificates");
        }
        const renderItem = ({ item }) => (
            <CertificateView data={item} />
          );
        return(
            <SafeAreaView style={{flex:1}}>
                <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }} style={{flex:1, alignSelf:"center"}}>
                    
                    <Text style={styles.title}>{i18n.t("certificatesTitle")}:</Text>
                    {Dimensions.get("window").width < 700 || Platform.OS == "ios" || Platform.OS == "android"?
                    <>
                    <FlatList
                        key={'_'}
                        data={this.state.certificates}
                        renderItem={renderItem}
                        keyExtractor={item => "_"+item.id}
                        numColumns={1}
                        contentContainerStyle={{alignItems:"center", width:"100vw", flex:1}}
                        style={styles.listOfCerts}
                    />
                    {Platform.OS == "web"? 
                    <Footer />
                    : undefined}
                    </>
                
                    :
                    <>
                    <FlatList
                        key={'#'}
                        data={this.state.certificates}
                        renderItem={renderItem}
                        keyExtractor={item => "#"+item.id}
                        numColumns={3}
                        contentContainerStyle={{alignItems:"center", width:"100vw", flex:1 }}
                        style={styles.listOfCerts}
                    />
                    
                    {Platform.OS == "web"? 
                        <Footer navigation={this.props.navigation} />
                    : undefined}
                    </>
                }

                    
                </ScrollView>
            </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({
    container:{
        flex:1,
        justifyContent:"center",
        alignContent:"center",
    },
    title:{
        fontWeight:"bold",
        fontSize: 35,
        textAlign:"center",
        marginTop: 10,
    },
    subtitle:{
        fontWeight:"bold",
        fontSize: 20,
        textAlign:"center",
        marginTop: 20,
    },
    searchTextBox:{
        
    },
    listOfCerts:{
        marginTop:20,
        flex:1,
    },
    

    
})