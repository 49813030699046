import React from 'react';
import { StyleSheet, Text, View, Button, TouchableOpacity, Platform } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { Ionicons } from '@expo/vector-icons';
import LaunchPage from './LaunchPage';
import Jobs from './Jobs';
import Certificates from './Certificates';
import JobPage from './JobPage';
import Companies from './Companies';
import CompanyPage from './CompanyPage';
import Account from './Account';
import About from './About';
import Hire from './Hire';

import Header from '../Components/Header';
import i18n from "i18n-js";
const Drawer = createDrawerNavigator();

const linking = {
  prefixes: ['https://bf.baara.net', 'baara://'],
  config: {
    screens: {
      LaunchPage: '',
      Jobs: 'jobs',
      JobPage:"jobs/:id",
      Companies: 'companies',
      Certificates: 'certificates',
      CompanyPage:"companies/:id",
      Hire:"hire",
      Account: 'account',
      About: 'about',
    }
  },
};

export default function Navigaitor(){
  
  return(
    <NavigationContainer linking={linking}>
        <Drawer.Navigator initialRouteName={Platform.OS == "web"?"LaunchPage":"Jobs"} screenOptions={{drawerActiveBackgroundColor: "#800080", drawerActiveTintColor:"black",header: (props) => <Header navigation={props.navigation}  />,}} >
            <Drawer.Screen name="LaunchPage"  component={LaunchPage} options={{drawerLabel: () => null, drawerActiveBackgroundColor: "#FFFFFF", unmountOnBlur: true, drawerItemStyle: { height: 0 } }} />
            <Drawer.Screen name="Jobs"  component={Jobs} options={{title:i18n.t("navTitleJobs")}} />
            <Drawer.Screen name="Companies" component={Companies} options={{title:i18n.t("navTitleCompanies")}} />
            <Drawer.Screen name="Certificates" component={Certificates} options={{title:i18n.t("navTitleCertificates")}} />
            <Drawer.Screen name="About" component={About} options={{title:i18n.t("navTitleAbout")}} />
            <Drawer.Screen name="Account" component={Account} options={{title:i18n.t("navTitleAccount")}} />

            
            <Drawer.Screen name="JobPage"  component={JobPage} options={{drawerLabel: () => null, drawerActiveBackgroundColor: "#FFFFFF", unmountOnBlur: true, drawerItemStyle: { height: 0 } }} />
            {Platform.OS==="web"?
              <Drawer.Screen name="Hire"  component={Hire} options={{drawerLabel:() => null, drawerActiveBackgroundColor: "#FFFFFF", unmountOnBlur: true, drawerItemStyle: { height: 0 } }} />
            :
            <Drawer.Screen name="Hire"  component={Hire} options={{title:i18n.t("navTitleHire"), drawerActiveBackgroundColor: "#FFFFFF", unmountOnBlur: true, }} />
            }
            
            <Drawer.Screen name="CompanyPage" component={CompanyPage} options={{drawerLabel: () => null, drawerActiveBackgroundColor: "#FFFFFF", unmountOnBlur: true, drawerItemStyle: { height: 0 }  }} />
        </Drawer.Navigator>
    </NavigationContainer>
  )
}
