import React, {useState} from "react";
import { View, Text, StyleSheet, FlatList, SafeAreaView, Platform, Dimensions, StatusBar} from "react-native";
import JobListItem from "../Components/JobListItem";
import { getJobs, getUserData } from "../Modules/Firebase";
import Home from "../Components/Home";
import isMobile from "../Modules/isMobile";
import SearchResult from "../Components/SearchResult";
import Footer from "../Components/Footer";
import JobPage from "./JobPage";
import JobsFilter from "../Components/JobsFilter";
import { ScrollView } from "react-native-gesture-handler";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import i18n from 'i18n-js';
var myThis;
export default class Jobs extends React.Component{
    
    constructor(props){
        super(props);
        this.state ={
            jobs:[{title:"Loading...", id:"123"}],
            showModal: false,
            selectedJob: null,
            search: (props.route.params && props.route.params.search)?  props.route.params.search : null,
            isMobile: null,
            jobId: null,
            userData: null,
            signedIn: null,

        }
        myThis = this;
    }
    componentDidMount(){
        if(Platform.OS === "web"){
            if(this.state.isMobile == null){
                if(Dimensions.get("window").width > 800){ 
                    this.setState({isMobile:false});
                }else{
                    this.setState({isMobile:true});
                }
            }
            Dimensions.addEventListener(
                "change",
                ({ window, screen }) => {
                    if(window.width > 800){
                        this.setState({isMobile:false});
                        
                    }else{
                        this.setState({isMobile:true});
                    }
                    
                }
              );
        }
        
    }
    
    toggleJobModal(value:Boolean){
        if(value){
            this.setState({showModal: value})
        }else{
            this.setState({showModal: !this.state.showModal})
        }
        
    }


    searchJobs(){
        getJobs(this, "jobs", this.props.route?.params?.search);
    }
    handleFilter(filterType){
        
        if(filterType == "recent"){
            myThis.setState({jobs:[{title:"Loading...", id:"123"}]})
            return;
        }
        if(myThis.state.signedIn){
            if(filterType == "loved"){
                if(myThis.state.userData && myThis.state.userData.saveForLater && myThis.state.userData.saveForLater.length != 0){
                    getJobs(myThis, "jobs",  myThis.state.userData.saveForLater);
                }else{
                    myThis.setState({jobs:[{title:i18n.t("jobsFilterNoSavedJobs"), id:"123"}]});
                }
            }
            if(filterType == "recommend"){
                if(myThis.state.userData && (myThis.state.userData.saveForLater && myThis.state.userData.saveForLater.length != 0) || (myThis.state.userData.jobsApplied && myThis.state.userData.jobsApplied.length != 0) ){
                    getJobs(myThis, "jobs",  myThis.state.userData.saveForLater);
                }else{
                    myThis.setState({jobs:[{title:i18n.t("jobsFilterNoSavedJobs"), id:"123"}]});
                }
            }
            return;
            
        }else{
            //if userData, need to be signed in
            if(filterType == "recommend" || filterType == "loved"){
                myThis.setState({jobs:[{title:i18n.t("jobsFilterSignIn"), id:"login"}]});
            }
            return;
        }
        
        
        
    }
    componentDidUpdate(){
        if(this.props.route?.params?.search && this.props.route.params.search != this.state.search){
            this.setState({search: this.props.route.params.search})
            this.searchJobs()
        }
        if(this.state.search && (this.props.route?.params == null || this.props.route?.params.search == null )){
            this.setState({search: null})
            this.searchJobs()
        }
    }
    render(){
        myThis = this;
        if(this.state.signedIn == null){
            onAuthStateChanged(getAuth(), (user) => {
                if (user) {
                  this.setState({signedIn: true});
                } else {
                    this.setState({signedIn: false});
                }
            });
        }
        if(this.state?.jobs && this.state?.jobs[0] && this.state?.jobs[0].title && this.state?.jobs[0].title === "Loading..."){
            this.searchJobs();
            
        }else if(this.state?.jobs && this.state?.jobs[0] && this.state?.jobs[0].id && this.state.jobId == null){
            
            this.setState({jobId:this.state?.jobs[0].id});
        }
        if(this.state.userData == null && this.state.signedIn == true){
            getUserData(this, "userData");

        }
        
        //homePageDescription
        const renderItem = ({ item }) => (
            <JobListItem isMobile={this.state.isMobile} jobId={this.state.jobId} data={item} onPress={(selectedJob)=>{
                if(item.id == "123"){// do nothing
                    return;
                }
                if(item.id == "login"){// go to login page
                    this.props.navigation.navigate('Account');
                    return;
                }
                if(this.state.isMobile){
                    this.props.navigation.navigate('JobPage', {id:item.id});
                }else{
                    this.setState({jobId:item.id})
                }
                
            }} />
        );
        return(
            
            <SafeAreaView style={{flex:1}}>
               <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }} style={{flex:1, alignSelf:"center"}}>
                    {this.state.search?
                        <SearchResult text={this.state.search} clearSearch={() => {
                            this.props.navigation.navigate('Jobs');
                        }} />
                        :
                        undefined
                    }
                    <JobsFilter data={this.state.jobs} handleFilter={this.handleFilter} userData={this.state.userData}/>
                    {this.state.isMobile? 
                        <FlatList
                        style={styles.jobsList}
                        contentContainerStyle={[{ flexGrow: 1, flex:1 }, styles.listOfJobs]}
                        data={this.state.jobs}
                        renderItem={renderItem}
                        keyExtractor={item => item.id}
                        />
                        
                    :
                        <View style={[{flexDirection:"row", flex: 1, maxWidth: 1000,  alignSelf:"center" }, styles.jobsList]}>
                            <FlatList
                            style={[{maxWidth: 400}]}
                            contentContainerStyle={[{ flex:1, flexGrow: 1, maxWidth: 400 }, styles.listOfJobs]}
                            data={this.state.jobs}
                            renderItem={renderItem}
                            keyExtractor={item => item.id}
                            />
                            <ScrollView style={styles.splitView}>
                                <JobPage navigation={this.props.navigation} splitView={true} jobId={this.state.jobId} />
                            </ScrollView>
                        </View>
                    }
                    
                    
                    {Platform.OS == "web"? 
                        <Footer navigation={this.props.navigation}/>
                    : undefined}
                </ScrollView>
            </SafeAreaView>
            
        );
    }

}
const styles = StyleSheet.create({
    splitView:{
        borderColor:"lightgray",
        borderWidth: 1,
        flex:1,
        borderRadius:10,
        backgroundColor:"white",
        minWidth: 400,
    },
    listOfJobs:{
        marginRight:10,
    },
    jobsList:{
        marginHorizontal:30,
        marginTop:10,
        marginBottom:10,
        
    }
})