import React, {useState} from "react";
import { View, FlatList, SafeAreaView, Text, StyleSheet, ScrollView, Platform, Pressable, Linking } from "react-native";
import CustomButton from "../Components/CustomButton";
import {contactBara} from "../Modules/GlobalFunctions";
import { Ionicons } from '@expo/vector-icons';
import i18n from "i18n-js";
import Footer from "../Components/Footer";
export default class About extends React.Component{
    constructor(props){
        super(props);
        
    }

    render(){
        
        return(
            <SafeAreaView style={{flex:1}}>
                <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }} style={{flex:1, alignSelf:"center"}}>
                    <Text style={styles.title}>{i18n.t("aboutTitle")}:</Text>
                    <Text style={styles.subtitle}>{i18n.t("aboutMissionTitle")}</Text>
                    <View style={styles.aboutContainer}>
                        <Text style={styles.aboutText}>{i18n.t("aboutMission")}</Text>
                    </View>
                    <Text style={styles.subtitle}>{i18n.t("aboutVisionTitle")}</Text>
                    <View style={styles.aboutContainer}>
                        <Text style={styles.aboutText}>{i18n.t("aboutVision")}</Text>
                    </View>
                    <Text style={styles.subtitle}>{i18n.t("aboutContactTitle")}</Text>
                    <View style={{marginVertical: 20}}>
                        <CustomButton text={i18n.t("aboutContactButton")} onPress={()=>contactBara()} />
                    </View>

                    {Platform.OS === "ios" || Platform.OS === "android" ? 
                        <>
                        <Text style={styles.subtitle}>{i18n.t("aboutSocialButton")}</Text>
                        <View style={styles.socialMediaView}>
                            <Pressable onPress={()=>{
                                Linking.openURL("https://www.facebook.com/baarajob/")
                            }}>
                                <Ionicons name="logo-facebook" size={50} color={"#800080"} style={styles.socialMediaIcon}/>
                            </Pressable>
                            <Pressable onPress={()=>{
                                Linking.openURL("https://twitter.com/baarajob")
                            }}>
                                <Ionicons name="logo-twitter" size={40} color={"#800080"} style={styles.socialMediaIcon}/>
                            </Pressable>
                            <Pressable onPress={()=>{
                                Linking.openURL("https://www.youtube.com/channel/UCNrtocRtDMThdrQ3tMO4RRg")
                            }}>
                                <Ionicons name="logo-youtube" size={40} color={"#800080"} style={styles.socialMediaIcon}/>
                            </Pressable>
                            <Pressable onPress={()=>{
                                Linking.openURL("https://www.instagram.com/baarajob/")
                            }}>
                                <Ionicons name="logo-instagram" size={40} color={"#800080"} style={[styles.socialMediaIcon, {marginHorizontal: 4}]}/>
                            </Pressable>
                        </View>
                        </>
                    : undefined}

                    {Platform.OS == "web"? 
                        <Footer navigation={this.props.navigation}/>
                    : undefined}
                </ScrollView>
                
            </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({
    container:{
        flex:1,
        justifyContent:"center",
        alignContent:"center",
    },
    title:{
        fontWeight:"bold",
        fontSize: 35,
        textAlign:"center",
        marginTop: 10,
    },
    subtitle:{
        fontWeight:"bold",
        fontSize: 20,
        textAlign:"center",
        marginTop: 20,
    },
    aboutText:{
        fontSize: 17,
        textAlign:"center",
    },
    aboutContainer:{
        justifyContent:"center",
        alignContent:"center",
        alignSelf:"center",
        marginTop: 10,
        backgroundColor:"white",
        padding: 20,
        marginHorizontal: 20,
        maxWidth: 650,
        borderRadius: 10, 
        borderWidth:2,
        borderColor:"black",
    },
    socialMediaView:{
        flexDirection:"row",
        alignItems:"center",
        marginTop:5,
        padding: 20,
        justifyContent:"center",
        marginBottom: 20,
    },
    socialMediaIcon:{
        marginHorizontal:8,
    }

    
})