import React, {useState} from "react";
import { View, StyleSheet, Text, TouchableOpacity, TextInput, TouchableHighlight, Platform, SafeAreaView, ScrollView } from "react-native";
import {isSignedIn, signIn, signUp} from "../Modules/Firebase";
import CustomButton from "../Components/CustomButton";
import i18n from "i18n-js";
import ShowToast from '../Modules/ShowToast';
import AccountView from "../Components/AccountView"
import Toast from "react-native-toast-notifications";
import Footer from "../Components/Footer";
let myThis;
export default class Account extends React.Component{
    constructor(props){
        super(props);
        this.state={
            showView:"signUp",
            isSignedIn: false,
            navigation: props.navigation,
            email:"",
            password:"",
            passwordRetype:"",
            toastMessage: "",
        }
        myThis = this;
        
    }
    updateSignInStatus(isSignedIn){
        if(isSignedIn != null){
            myThis.setState({isSignedIn: isSignedIn});  
        }
        
    }
    componentDidMount() {
        isSignedIn(this.updateSignInStatus);
    };
    render(){
        
        return(
            
            <SafeAreaView style={{flex:1}}>
                {/* Toast Display */}
                <ShowToast message={this.state.toastMessage} didShow={()=>this.setState({toastMessage:""})}/>
                
                <ScrollView contentContainerStyle={styles.container}>
                    {this.state.isSignedIn?     
                    <>
                        <AccountView navigation={this.props.navigation} setToast={(text)=>{
                            if(text){this.setState({toastMessage: text})};
                        }}/>
                    </>
                    : 
                        <View style={styles.signUpContainer}>

                            <View style={styles.segmentContainer}>
                                {this.state.showView==="forgotPassword"? 

                                    <Text style={styles.accountTitle}>{i18n.t("accountForgotPasswordTitle")}</Text>

                                    :
                                    <>
                                        <TouchableHighlight onPressOut={() => this.setState({showView:"signUp"}) }>
                                            <Text style={this.state.showView === "signUp"?  styles.accountTitleActive : styles.accountTitle}>{i18n.t("accountSignUpTitle")}</Text>
                                        </TouchableHighlight>
                                        <View style={{marginHorizontal:10}}></View>
                                        <TouchableHighlight onPressOut={() => this.setState({showView:"signIn"}) }>
                                            <Text style={this.state.showView === "signIn"?  styles.accountTitleActive : styles.accountTitle}>{i18n.t("accountSignInTitle")}</Text>
                                        </TouchableHighlight>
                                    </>
                                }
                                
                                
                            </View>
                            {(()=>{
                                if(this.state.showView==="signUp"){
                                    return(
                                        <>
                                            <Text style={styles.labelTextInput}>{i18n.t("accountSignUpEmailLabel")}</Text>
                                            <TextInput keyboardType={"email-address"} onChangeText={text => this.setState({email:text})} style={styles.textInput} />
                                            <Text style={styles.labelTextInput}>{i18n.t("accountSignUpPasswordLabel")}</Text>
                                            <TextInput onChangeText={text => this.setState({password:text})} style={styles.textInput} secureTextEntry={true}/>
                                            <Text style={styles.labelTextInput}>{i18n.t("accountSignUpPasswordVerifyLabel")}</Text>
                                            <TextInput onChangeText={text => this.setState({passwordRetype:text})} style={styles.textInput} secureTextEntry={true}/>
                                            <CustomButton text={i18n.t("accountSignUpButtonText")} onPress={() => {
                                                if(this.state.email == ""){
                                                    this.setState({toastMessage: i18n.t("accountEmailBlankAlert")}); 
                                                }else if(this.state.password == ""){
                                                    this.setState({toastMessage:i18n.t("accountPasswordBlankAlert")}); 
                                                }else if(this.state.password != this.state.passwordRetype ){
                                                    this.setState({toastMessage: i18n.t("")}); 
                                                }else{
                                                    const tempThis = this;
                                                    signUp(this.state.email, this.state.password, function (event) {
                                                        if(event.isError){
                                                            tempThis.setState({toastMessage: ""+event.error}); 
                                                        }else{
                                                            tempThis.setState({toastMessage:i18n.t("accountAlertSignedUp")}); 
                                                        }
                                                    });
                                                }
                                            }}/>
                                            
                                        </>
                                    )
                                }else if(this.state.showView==="signIn"){
                                    return(
                                        <>
                                            <Text style={styles.labelTextInput}>{i18n.t("accountSignInEmailLabel")}</Text>
                                            <TextInput keyboardType={"email-address"} style={styles.textInput} onChangeText={text => this.setState({email:text})}/>
                                            <Text style={styles.labelTextInput}>{i18n.t("accountSignInPasswordLabel")}</Text>
                                            <TextInput style={styles.textInput} onChangeText={text => this.setState({password:text})} secureTextEntry={true}/>
                                            <CustomButton text={i18n.t("accountSignInButtonText")} onPress={() =>{
                                                
                                                if(this.state.email == ""){
                                                    this.setState({toastMessage: i18n.t("accountEmailBlankAlert")}); 
                                                }else if(this.state.password == ""){
                                                    this.setState({toastMessage: i18n.t("accountPasswordBlankAlert")}); 
                                                }else{
                                                    
                                                    const tempThis = this;
                                                    signIn(this.state.email, this.state.password, function (event) {
                                                        if(event.isError){
                                                            tempThis.setState({toastMessage: ""+event.error}); 
                                                        }else{
                                                            tempThis.setState({toastMessage: i18n.t("accountAlertSignedIn")}); 
                                                        }
                                                    });
                                                }
                                            } } />
                                            <CustomButton text={i18n.t("accountForgotButtonText")} onPress={() => this.setState({showView:"forgotPassword"})}/>
                                        </>
                                    )
                                }else if(this.state.showView==="forgotPassword"){
                                    return(
                                        <>
                                            <Text style={styles.labelTextInput}>{i18n.t("accountSignInEmailLabel")}</Text>
                                            <TextInput keyboardType={"email-address"} style={styles.textInput}/>
                                            <CustomButton text={i18n.t("accountResetButtonText")} onPress={() =>{

                                            } } />
                                            <CustomButton text={i18n.t("accountResetCancelButtonText")} onPress={() => this.setState({showView:"signIn"})}/>
                                        </>
                                    )
                                }

                            })()}
                        </View>
                    }
                    {Platform.OS == "web"? 
                        <Footer navigation={this.props.navigation}/>
                        : undefined}
                </ScrollView>

                    
                   
             </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({
    container:{
        alignSelf:"center",
        flex: 1,
    },
    segmentContainer:{
        flexDirection:"row",
        alignSelf:"center",
        padding:10,
    },
    signUpContainer:{
        marginTop: 50,
        backgroundColor:"lightgray",
        padding: 50,
        borderRadius: 10,
        marginBottom: 50,
        maxWidth: 500,
        alignSelf:"center",
    },
    accountTitle:{
        fontSize: 40,
        padding:5,
        fontWeight:"bold",
        textAlign: "center",
    },
    accountTitleActive:{
        fontSize: 40,
        fontWeight:"bold",
        textAlign: "center",
        textDecorationLine:"underline",
        textDecorationColor:"#800080",
        padding:5,
    },
    labelTextInput:{
        fontSize: 20,
        textAlign: "center",
        marginTop: 10,
        marginBottom: 5,
    },
    textInput:{
        backgroundColor: "white",
        fontSize: 25,
        marginBottom: 10,
    },

})